import { Box } from 'theme-ui';
import { useState, useLayoutEffect, useRef } from 'react';

import gsap from 'gsap';
import { usePropertiesLink } from '../hooks/usePropertiesLink';
import { AngelSlider } from './layout/AngelSlider';
import { CTAButton } from './core/themed';
import { Link } from './core/router/Link';
import { NavigationBottom } from './core/NavigationBottom';
import __ from '../hooks/useI18n';

const styles = {
  sliderBox: {
    marginY: '6',
    mx: 'auto',
    width: 'fullSize',
    px: '3.3vh',
    height: (theme) => theme.sizes.getRelativeHeight(0.556),
  },
  bottomCTA: {
    variant: 'flex.center',
    pt: '5vh',
    height: '11vh',
  },
};

export const RiskSelector = ({ steps }) => {
  const link = usePropertiesLink(steps.risk.link);
  const buttonRef = useRef(null);

  const [sliderIndex, setSliderIndex] = useState(1);
  const [hasChanged, setChanged] = useState(false);

  const onIndexChange = (index) => {
    setSliderIndex(index);
    setChanged(true);
  };

  useLayoutEffect(() => {
    gsap.timeline()
      .to('#clouds', {
        y: '0',
        opacity: 1,
        ease: 'circ',
        duration: 1.2,
      }).from(buttonRef.current, {
        opacity: 0,
      });
  }, [buttonRef]);

  const risks = [
    {
      text: () => __('Neues ist mir erst einmal suspekt, ich vermeide grundsätzlich jedes Risiko.'),
    },
    {
      text: () => __('Ich plane vorraus und versuche so Risiken so gut es geht zu vermeiden.'),
    },
    {
      text: () => __('Ich bin bereit für Abenteuer, zu extrem sollten sie jedoch nicht sein.'),
    },
    {
      text: () => __('Ein Leben ohne Risiko macht keinen Spaß, solange es am Ende gut ausgeht.'),
    },
    {
      text: () => __('Warum all die Gedanken, ich schrecke vor keiner Herausforderung zurück!'),
    },
  ];

  return (
    <>
      <Box sx={styles.sliderBox}>
        <AngelSlider
          sliderIndex={sliderIndex}
          setSliderIndex={onIndexChange}
          data={risks}
        />
      </Box>

      <Box sx={styles.bottomCTA} ref={buttonRef}>
        <CTAButton active={hasChanged}>
          <Link to={link}>
            {__('Schutzprofil generieren')}
          </Link>
        </CTAButton>
      </Box>

      <NavigationBottom />
    </>
  );
};

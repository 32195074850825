import { useLocation } from 'react-router-dom';

export const selectionDelimiters = Object.freeze({
  multiple: ',',
  single: '/',
});

export const appendLinkNewProperty = (more, delimiter = selectionDelimiters.single) => `${more !== undefined ? `${delimiter}${more}`: ''}`;

export const getPropertiesLinkNextSection = ({ pathname, root, more }) => `${root}${pathname.replace(/^\/[^/]+/, '')}${appendLinkNewProperty(more)}`;

const getLinkPrefix = ({ pathname, maxDepth }) => {
  const matcher = new RegExp(`^((/[\\w-,]+){${maxDepth-1}}).*`);
  const prefix = pathname.replace(matcher, '$1');
  return prefix;
};

export const getPropertiesLinkNextStep = ({ pathname, more, maxDepth }) => {
  const prefix = getLinkPrefix({ pathname, maxDepth });
  const postfix = appendLinkNewProperty(more);
  return `${prefix}${postfix}`;
};

export const getPropertiesLinkMultipleItem = ({ pathname, more }) => {
  const newPathname = pathname
    .replace(new RegExp('(/[^/]*)-1([^/]*)$', 'g'), '$1XX$2')
    .replace(new RegExp(`(/[^/]*)${more}([^/]*)$`, 'g'), '$1$2')
    .replace(new RegExp('(/[^/]*)XX([^/]*)$', 'g'), '$1-1$2');
  if (newPathname !== pathname) {
    // deletion!
    return newPathname;
  }
  return `${pathname}${appendLinkNewProperty(more, selectionDelimiters.multiple)}`;
};

export const usePropertiesLink = (linkFunction = getPropertiesLinkNextStep, more = undefined) => {
  const location = useLocation();

  return linkFunction({ pathname: location.pathname, more });
};

/* eslint-disable jsx-a11y/anchor-is-valid */
import CustomEase from 'gsap/CustomEase';
import gsap from 'gsap/gsap-core';
import { useEffect, useRef } from 'react';
import { Box } from 'theme-ui';
import { Link } from './Link';
import __ from '../../../hooks/useI18n';

export const NextButton = ({ link, disabled }) => {
  const ref = useRef(null);
  gsap.registerPlugin(CustomEase);

  useEffect(() => {
    if (!disabled) {
      gsap.timeline({
        repeat: -1,
        delay: 2,
      })
        .to(ref.current, {
          x: 20,
          ease: CustomEase.create('custom', 'M0,0,C0,0,0,1,0.154,1,0.304,1,0.168,0.568,0.33,0.568,0.488,0.568,0.338,1,0.488,1,0.711,1,0.567,0.011,0.71,0,0.976,-0.022,1,0,1,0'),
          duration: 1,
        })
        .to({}, {
          duration: 1.5,
        });
    }
  }, [ref, disabled]);

  return (
    <Box
      sx={{
        variant: 'buttons.smallCta',
        opacity: disabled? 0.4:1,
      }}
      ref={ref}
    >
      <Link to={disabled ? undefined : link}>
        {__('Weiter')}
        <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.01 3H0V5H12.01V8L16 4L12.01 0V3Z" fill="#3A7D44" />
        </svg>
      </Link>
    </Box>
  );
};

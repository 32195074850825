import React from 'react';

export default {
  Schutzengel: {
    de: 'Schutzengel',
    fr: 'Ange gardien',
    en: 'Guardian angel',
  },
  Geburtsdatum: {
    de: 'Geburtsdatum',
    fr: 'Date de naissance',
    en: 'Date of birth',
  },
  Beschäftigung: {
    de: 'Beschäftigung',
    fr: 'Profession',
    en: 'Occupation',
  },
  Nettoeinkommen: {
    de: 'Nettoeinkommen',
    fr: 'Revenu net',
    en: 'Net salary',
  },
  Familienstand: {
    de: 'Familienstand',
    fr: 'Situation familiale',
    en: 'Marital status',
  },
  Wohnverhältnis: {
    de: 'Wohnverhältnis',
    fr: 'Type de logement',
    en: 'Living arrangements',
  },
  Einrichtung: {
    de: 'Einrichtung',
    fr: 'Institution',
    en: 'Organisation',
  },
  Schätze: {
    de: 'Schätze',
    fr: 'Trésors',
    en: 'Treasures',
  },
  Ergebnis: {
    de: 'Ergebnis',
    fr: 'Résultat',
    en: 'Result',
  },
  'Zukunftspläne 1/4': {
    de: 'Zukunftspläne 1/4',
    fr: 'Projets pour l’avenir 1/4',
    en: 'Future plans 1/4',
  },
  'Zukunftspläne 2/4': {
    de: 'Zukunftspläne 2/4',
    fr: 'Projets pour l’avenir 2/4',
    en: 'Future plans 2/4',
  },
  'Zukunftspläne 3/4': {
    de: 'Zukunftspläne 3/4',
    fr: 'Projets pour l’avenir 3/4',
    en: 'Future plans 3/4',
  },
  'Zukunftspläne 4/4': {
    de: 'Zukunftspläne 4/4',
    fr: 'Projets pour l’avenir 4/4',
    en: 'Future plans 4/4',
  },
  'Das ist sehr vernünftig von Dir, man kann sich es auch mit einfacheren Mitteln gemütlich machen.': {
    de: 'Das ist sehr vernünftig von Dir, man kann sich es auch mit einfacheren Mitteln gemütlich machen.',
    fr: 'C’est très raisonnable de ta part, on peut aussi vivre confortablement avec des moyens plus modestes.',
    en: 'That’s very sensible of you, you can also make yourself comfortable with living in a simpler way.',
  },
  'Welche Dinge in Deinem Leben sind Dir wichtig. Was sind Deine persönlichen Schätze?': {
    de: 'Welche Dinge in Deinem Leben sind Dir wichtig. Was sind Deine persönlichen Schätze?',
    fr: 'Quelles choses te sont particulièrement importantes dans la vie ? Quels sont tes trésors personnels ?',
    en: 'What things in your life are important to you? What are your personal treasures?',
  },
  'Was ist Dir besonders wichtig bei der Absicherung Deiner Gesundheit?': {
    de: 'Was ist Dir besonders wichtig bei der Absicherung Deiner Gesundheit? ',
    fr: 'Que trouves-tu particulièrement important pour préserver ta santé ?',
    en: 'What’s particularly important to you when it comes to safeguarding your health?',
  },
  'Was sind Lebenswünsche, die Du Dir erfüllen möchtest?': {
    de: 'Was sind Lebenswünsche, die Du Dir erfüllen möchtest?',
    fr: 'Quels souhaits de vie aimerais-tu réaliser ?',
    en: 'What would you like to tick off your bucket list?',
  },
  'Toll, lass uns loslegen! Wann bist Du geboren?': {
    de: 'Toll, lass uns loslegen! Wann bist Du geboren?',
    fr: 'Super, c’est parti ! Quand es-tu né·e ?',
    en: 'Great, let’s get started! When were you born?',
  },
  'Cool, dann bist Du Steinbock. Super, dass Du noch so jung bist, dann kann ich Dich länger beschützen.': {
    de: 'Cool, dann bist Du Steinbock. Super, dass Du noch so jung bist, dann kann ich Dich länger beschützen.',
    fr: 'Cool, alors tu es Capricorne. C’est bien que tu sois encore si jeune, je pourrai donc te protéger plus longtemps.',
    en: 'Cool, then you’re a Capricorn. It’s great that you’re still so young, so I can protect you for longer.',
  },
  'Welche der folgenden Kategorien beschreibt Dein Beschäftigungsstatus aktuell am besten?': {
    de: 'Welche der folgenden Kategorien beschreibt Dein Beschäftigungsstatus aktuell am besten?',
    fr: 'Laquelle des catégories suivantes décrit le mieux ta situation professionnelle actuelle ?',
    en: 'Which of the following categories best describes your current employment status?',
  },
  'Danke Dir, dann bist Du unter den zwei Drittel der Erwerbstätigen, die angestellt sind. Ein pünktliches, sicheres Gehalt ist auch etwas Schönes.': {
    de: 'Danke Dir, dann bist Du unter den zwei Drittel der Erwerbstätigen, die angestellt sind. Ein pünktliches, sicheres Gehalt ist auch etwas Schönes.',
    fr: 'Merci, alors tu fais partie des deux tiers de la population active qui ont un emploi. C’est agréable de recevoir un salaire ponctuel et sûr.',
    en: 'Thanks, then you’re among the two-thirds of the working population who are employed. Having a regular, secure salary is also a nice thing.',
  },
  'Teilst Du mir grob Dein monatliches Nettoeinkommen mit, damit ich das Schutzprofil passgenau auf Deine persönliche Situation anpassen kann?': {
    de: 'Teilst Du mir grob Dein monatliches Nettoeinkommen mit, damit ich das Schutzprofil passgenau auf Deine persönliche Situation anpassen kann?',
    fr: 'Peux-tu me dire en gros quel est ton revenu net mensuel afin que je puisse adapter le profil de protection à ta situation personnelle ?',
    en: 'Can you give me a rough idea of your monthly net income so that I can tailor the protection profile to your personal situation?',
  },
  'Danke Dir! Ich habe mal kurz für Dich nachgerechnet: Bis zu Deiner Rente wirst Du über 1,5 Millionen Euro verdienen und da sind zukünftige Gehaltserhöhungen noch nicht mit eingerechnet.': {
    de: 'Danke Dir! Ich habe mal kurz für Dich nachgerechnet: Bis zu Deiner Rente wirst Du über 1,5 Millionen Euro verdienen und da sind zukünftige Gehaltserhöhungen noch nicht mit eingerechnet.',
    fr: 'Merci ! J’ai fait un calcul rapide pour toi : au moment de la retraite, tu auras gagné plus d’1,5 million d’euros, sans compter les futures augmentations de salaire.',
    en: 'Thank you! I did a quick calculation for you: by the time you retire, you’ll earn over 1.5 million euros, and that doesn’t include future salary increases.',
  },
  'Verrätst Du mir nun noch, wie es mit Deinen familiären Verhältnissen aussieht?': {
    de: 'Verrätst Du mir nun noch, wie es mit Deinen familiären Verhältnissen aussieht?',
    fr: 'Peux-tu me dire maintenant quelle est ta situation familiale ?',
    en: 'Can you tell me now what your marital status is?',
  },
  'Glückwunsch! Dann hast Du Deine bessere Hälfte bereits gefunden. Gemeinsam lässt sich das Leben doch umso mehr genießen.': {
    de: 'Glückwunsch! Dann hast Du Deine bessere Hälfte bereits gefunden. Gemeinsam lässt sich das Leben doch umso mehr genießen. ',
    fr: 'Félicitations ! Alors tu as déjà trouvé ta moitié. La vie est beaucoup plus douce à deux.',
    en: 'Congratulations! Then you’ve already found your better half. Life’s better with two.',
  },
  'Wir brauchen alle ein Dach über dem Kopf – wie sieht Deines aus?': {
    de: 'Wir brauchen alle ein Dach über dem Kopf – wie sieht Deines aus? ',
    fr: 'Il nous faut tous un toit au-dessus de la tête. De quoi a l’air le tien ?',
    en: 'We all need a roof over our heads – what does yours look like?',
  },
  'Mit 23 Jahren ist das Wohnen zur Miete auch sinnvoll. Du kannst später immer noch entscheiden, ob Du Dir Deine eigenen vier Wände zulegen möchtest.': {
    de: 'Mit 23 Jahren ist das Wohnen zur Miete auch sinnvoll. Du kannst später immer noch entscheiden, ob Du Dir Deine eigenen vier Wände zulegen möchtest. ',
    fr: 'Il est plutôt logique de louer son logement à l’âge de 23 ans. Tu peux toujours décider plus tard si tu préfères avoir ton propre chez-toi.',
    en: 'At aged 23, living in rented accommodation also makes sense. You can always decide later whether you want to buy your own four walls.',
  },
  'Besitzt Du Einrichtungsgegenstände, die in Summe, mehr als 20.000 Euro wert sind?': {
    de: 'Besitzt Du Einrichtungsgegenstände, die in Summe, mehr als 20.000 Euro wert sind?',
    fr: 'Possèdes-tu des meubles d’une valeur totale supérieure à 20 000 euros ?',
    en: 'Do you own furniture that’s worth more than 20,000 euros in total?',
  },
  'Super, wir haben es auch schon fast geschafft. Lass uns nun zum Abschluss noch zu Deinen persönlichen Zukunftsplänen kommen.': {
    de: 'Super, wir haben es auch schon fast geschafft. Lass uns nun zum Abschluss noch zu Deinen persönlichen Zukunftsplänen kommen.',
    fr: 'Super, on y est presque. Maintenant, parlons de tes projets personnels pour l’avenir.',
    en: 'Super, we’re almost there now. Finally, let’s talk about your personal plans for the future.',
  },
  'Klasse, danke Dir! Nun noch eine letzte Frage, dann sind wir durch. Welcher Risikotyp bist Du?': {
    de: 'Klasse, danke Dir! Nun noch eine letzte Frage, dann sind wir durch. Welcher Risikotyp bist Du?',
    fr: 'Excellent, merci ! Une dernière question, puis on aura terminé. Quel est ton profil de risque ?',
    en: 'Great, thank you! Now one last question, then we’re all done. Which risk type are you?',
  },
  'Danke Dir vielmals für Deine Antworten und Deine Offenheit. Ich werde nun Deinen Schutzplan individualisieren.': {
    de: 'Danke Dir vielmals für Deine Antworten und Deine Offenheit. Ich werde nun Deinen Schutzplan individualisieren. ',
    fr: 'Merci beaucoup pour tes réponses et ta sincérité. Je vais maintenant personnaliser ton plan de protection.',
    en: 'Thank you very much for your answers and your openness. I will now individualise your protection plan.',
  },
  'Danke für Deine Geduld! Auf Basis Deiner Antworten haben ich den für Dich passenden Schutzplan bestehend aus vier Paketen ermittelt.': {
    de: 'Danke für Deine Geduld! Auf Basis Deiner Antworten haben ich den für Dich passenden Schutzplan bestehend aus vier Paketen ermittelt.',
    fr: 'Merci pour ta patience ! Je me suis basé sur tes réponses pour composer un plan de protection adapté à tes besoins et composé de quatre paquets.',
    en: 'Thanks for waiting! Based on your answers, I’ve determined the protection plan that’s just right for you, consisting of four packages.',
  },
  'Mit Tap auf das jeweilige Schild erfährst Du mehr darüber, warum diese Absicherung für Dich relevant ist und wie Du diese individualisieren kannst.': {
    de: 'Mit Tap auf das jeweilige Schild erfährst Du mehr darüber, warum diese Absicherung für Dich relevant ist und wie Du diese individualisieren kannst.',
    fr: 'Tu peux effleurer un bouclier pour découvrir pourquoi cette couverture est pertinente pour toi et comment tu peux encore la personnaliser.',
    en: 'Tap on the respective sign to find out more about why this protection is relevant for you and how you can individualise it.',
  },
  'Mein Vorschlag ist auf Deine persönliche Situation abgestimmt. Mit einem Tab auf den jeweiligen Schutzplan erfährst Du mehr darüber.': {
    de: 'Mein Vorschlag ist auf Deine persönliche Situation abgestimmt. Mit einem Tab auf den jeweiligen Schutzplan erfährst Du mehr darüber.',
    fr: 'Ma recommandation dépend de ta situation personnelle. Effleure le plan de protection correspondant pour en apprendre plus à son sujet.',
    en: 'My suggestion is tailored to your personal situation. You can find out more about the respective protection plan by clicking on it.',
  },
  'Hier erkläre ich Dir meine Empfehlung und zeige Dir mehr Details zu dem Schutzplan.': {
    de: 'Hier erkläre ich Dir meine Empfehlung und zeige Dir mehr Details zu dem Schutzplan. ',
    fr: 'Je vais t’expliquer ici ma recommandation et te montrer les détails de ton plan de protection.',
    en: 'Here I’ll explain my recommendation and show you more details about the protection plan.',
  },
  Katze: {
    de: 'Katze',
    fr: 'Chat',
    en: 'Cat',
  },
  Hund: {
    de: 'Hund',
    fr: 'Chien',
    en: 'Dog',
  },
  Fahrrad: {
    de: 'Fahrrad',
    fr: 'Vélo',
    en: 'Bicycle',
  },
  Motorrad: {
    de: 'Motorrad',
    fr: 'Moto',
    en: 'Motorbike',
  },
  Auto: {
    de: 'Auto',
    fr: 'Voiture',
    en: 'Car',
  },
  Pferd: {
    de: 'Pferd',
    fr: 'Cheval',
    en: 'Horse',
  },
  Drohne: {
    de: 'Drohne',
    fr: 'Drone',
    en: 'Drone',
  },
  'Nichts davon': {
    de: 'Nichts davon',
    fr: 'Aucune de ces réponses',
    en: 'None of these',
  },
  'Freie Klinik und Krankenhauswahl': {
    de: 'Freie Klinik und Krankenhauswahl',
    fr: 'Libre choix de la clinique et de l’hôpital',
    en: 'Free choice of clinic and hospital',
  },
  Chefarztbehandlung: {
    de: 'Chefarztbehandlung',
    fr: 'Traitement auprès du médecin chef',
    en: 'Treatment by head physician',
  },
  Einzelzimmer: {
    de: 'Einzelzimmer',
    fr: 'Chambre individuelle',
    en: 'Private room',
  },
  'Bestmögliche Zahnabsicherung': {
    de: 'Bestmögliche Zahnabsicherung',
    fr: 'Meilleure assurance dentaire',
    en: 'Best possible dental insurance',
  },
  'Medizinische Zweitmeinung': {
    de: 'Medizinische Zweitmeinung ',
    fr: 'Deuxième avis médical',
    en: 'Second medical opinion',
  },
  'Ich brauche keine Extrawürste': {
    de: 'Ich brauche keine Extrawürste',
    fr: 'Je n’ai pas besoin de services supplémentaires',
    en: 'I don’t need any special treatment',
  },
  'Meine eigene Familie gründen': {
    de: 'Meine eigene Familie gründen ',
    fr: 'Fonder ma propre famille',
    en: 'Starting my own family',
  },
  'In meine eigenen vier Wänden ziehen': {
    de: 'In meine eigenen vier Wänden ziehen ',
    fr: 'Vivre dans mon propre chez-moi',
    en: 'Moving into my own home',
  },
  'Mir meinen Traum vom Boot erfüllen': {
    de: 'Mir meinen Traum vom Boot erfüllen',
    fr: 'Réaliser mon rêve de posséder un bateau',
    en: 'Fulfilling my dream of owning a boat',
  },
  'Ferienwohnung erwerben': {
    de: 'Ferienwohnung erwerben',
    fr: 'Acheter une maison de vacances',
    en: 'Purchasing a holiday home',
  },
  'Meine eigene Firma gründen': {
    de: 'Meine eigene Firma gründen',
    fr: 'Créer ma propre société',
    en: 'Starting my own business',
  },
  'Mir eine Weltreise ermöglichen': {
    de: 'Mir eine Weltreise ermöglichen',
    fr: 'Faire un tour du monde',
    en: 'Travelling the world',
  },
  'Selbständige*r': {
    de: 'Selbständige*r',
    fr: 'Profession indépendante',
    en: 'Self-employed',
  },
  'Freiberufler*n': {
    de: 'Freiberufler*n',
    fr: 'Profession libérale',
    en: 'Freelancer',
  },
  'Beamter*n': {
    de: 'Beamter*n',
    fr: 'Fonctionnaire',
    en: 'Civil servant',
  },
  'Auszubildende*r': {
    de: 'Auszubildende*r',
    fr: 'Apprentissage',
    en: 'Trainee',
  },
  'Angestellte*r': {
    de: 'Angestellte*r',
    fr: 'Employé·e',
    en: 'Employee',
  },
  'Student*n': {
    de: 'Student*n',
    fr: 'Étudiant·e',
    en: 'Student',
  },
  'Ruheständler*n': {
    de: 'Ruheständler*n',
    fr: 'Retraité·e',
    en: 'Retired',
  },
  'Haushalter*n': {
    de: 'Haushalter*n',
    fr: 'Homme/femme au foyer',
    en: 'Housekeeper',
  },
  'Unter 2.000€': {
    de: <>Unter<br />2.000€</>,
    fr: <>Moins de<br />2 000 €</>,
    en: <>Under<br />€2,000</>,
  },
  '2.000€ bis 3.000€': {
    de: <>2.000€<br />bis 3.000€</>,
    fr: <>2 000 €<br />à 3 000 €</>,
    en: <>€2,000<br />to €3,000</>,
  },
  '3.000€ bis 4.000€': {
    de: <>3.000€<br />bis 4.000€</>,
    fr: <>3 000 €<br />à 4 000 €</>,
    en: <>€3,000<br />to €4,000</>,
  },
  'Über 4.000€': {
    de: <>Über<br />4.000€</>,
    fr: <>Plus de<br />4 000 €</>,
    en: <>Over<br />€4,000</>,
  },
  'Single ohne Kinder': {
    de: <>Single<br />ohne Kinder</>,
    fr: <>Célibataire<br />sans enfant</>,
    en: <>Single<br />without children</>,
  },
  'Single mit Kindern': {
    de: <>Single<br />mit Kindern</>,
    fr: <>Célibataire<br />avec enfants</>,
    en: <>Single<br />with children</>,
  },
  'Partnerschaft ohne Kinder': {
    de: <>Partnerschaft<br />ohne Kinder</>,
    fr: <>Couple<br />sans enfant</>,
    en: <>Partner<br />without children</>,
  },
  'Partnerschaft mit Kindern': {
    de: <>Partnerschaft<br />mit Kindern</>,
    fr: <>Couple<br />avec enfants</>,
    en: <>Partner<br />with children</>,
  },
  Miete: {
    de: 'Miete',
    fr: 'Location',
    en: 'Rent',
  },
  Eigentum: {
    de: 'Eigentum',
    fr: 'Propriété',
    en: 'Property',
  },
  Mietfrei: {
    de: 'Mietfrei',
    fr: 'Sans loyer',
    en: 'Rent-free',
  },
  Ja: {
    de: 'Ja',
    fr: 'Oui',
    en: 'Yes',
  },
  Nein: {
    de: 'Nein',
    fr: 'Non',
    en: 'No',
  },
  'Empfohlene Absicherungen': {
    de: 'Empfohlene Absicherungen ',
    fr: 'Couvertures recommandées',
    en: 'Recommended protection',
  },
  'Weitere Absicherungen': {
    de: 'Weitere Absicherungen',
    fr: 'Couvertures supplémentaires',
    en: 'Further protection',
  },
  Arbeitskraft: {
    de: 'Arbeitskraft',
    fr: 'Capacité à travailler',
    en: 'Labour',
  },
  'Arbeitskraft//': {
    de: 'Arbeitskraft',
    fr: <>Capacité<br />à travailler</>,
    en: 'Labour',
  },
  Gesundheit: {
    de: 'Gesundheit',
    fr: 'Santé',
    en: 'Health',
  },
  'Sach- und Personenschäden': {
    de: 'Sach- und Personenschäden',
    fr: 'Dommages matériels et corporels',
    en: 'Property damage and personal injury',
  },
  Zukunftsvorsorge: {
    de: 'Zukunftsvorsorge',
    fr: 'Prévoyance vieillesse',
    en: 'Provision for the future',
  },
  'Deine Liebsten': {
    de: 'Deine Liebsten',
    fr: 'Tes proches',
    en: 'Your loved ones',
  },
  Wohnung: {
    de: 'Wohnung',
    fr: 'Logement',
    en: 'Accommodation',
  },
  Januar: {
    de: 'Januar',
    fr: 'Janvier',
    en: 'January',
  },
  Februar: {
    de: 'Februar',
    fr: 'Février',
    en: 'February',
  },
  März: {
    de: 'März',
    fr: 'Mars',
    en: 'March',
  },
  April: {
    de: 'April',
    fr: 'Avril',
    en: 'April',
  },
  Mai: {
    de: 'Mai',
    fr: 'Mai',
    en: 'May',
  },
  Juni: {
    de: 'Juni',
    fr: 'Juin',
    en: 'June',
  },
  Juli: {
    de: 'Juli',
    fr: 'Juillet',
    en: 'July',
  },
  August: {
    de: 'August',
    fr: 'Août',
    en: 'August',
  },
  September: {
    de: 'September',
    fr: 'Septembre',
    en: 'September',
  },
  Oktober: {
    de: 'Oktober',
    fr: 'Octobre',
    en: 'October',
  },
  November: {
    de: 'November',
    fr: 'Novembre',
    en: 'November',
  },
  Dezember: {
    de: 'Dezember',
    fr: 'Décembre',
    en: 'December',
  },
  'Neues ist mir erst einmal suspekt, ich vermeide grundsätzlich jedes Risiko.': {
    de: <>Neues ist mir erst<br />einmal suspekt, ich<br />vermeide grundsätzlich<br />jedes Risiko.</>,
    fr: <>Je me méfie au début<br />de ce qui est nouveau,<br />j’évite en principe<br />tout risque.</>,
    en: <>At first I’m suspicious<br /> of new things,<br />I always avoid any risk.</>,
  },
  'Ich plane vorraus und versuche so Risiken so gut es geht zu vermeiden.': {
    de: <>Ich plane vorraus<br />und versuche so<br />Risiken so gut es<br />geht zu vermeiden.</>,
    fr: <>Je prévoie à l’avance<br />et essaie ainsi<br />d’éviter au possible<br />les risques.</>,
    en: <>I plan ahead<br />and try to avoid risks<br />as much as possible.</>,
  },
  'Ich bin bereit für Abenteuer, zu extrem sollten sie jedoch nicht sein.': {
    de: <>Ich bin bereit für<br />Abenteuer, zu<br />extrem sollten sie<br />jedoch nicht sein.</>,
    fr: <>Je suis prêt à partir<br />à l’aventure, mais<br />ça ne doit pas<br />être trop extrême.</>,
    en: <>I’m up for adventures,<br />but they should<br />not be too extreme.</>,
  },
  'Ein Leben ohne Risiko macht keinen Spaß, solange es am Ende gut ausgeht.': {
    de: <>Ein Leben ohne<br />Risiko macht<br />keinen Spaß,<br />solange es am<br />Ende gut ausgeht.</>,
    fr: <>Vivre sans risque<br />n’est pas amusant,<br />l’important est que<br />tout finisse bien.</>,
    en: <>A life without risk<br /> is no fun, as long<br />as all ends well.</>,
  },
  'Warum all die Gedanken, ich schrecke vor keiner Herausforderung zurück!': {
    de: <>Warum all die<br />Gedanken, ich<br />schrecke vor keiner<br />Herausforderung<br />zurück!</>,
    fr: <>Pourquoi se poser <br />tant de questions, je ne recule<br />devant aucun défi!</>,
    en: <>I don’t overthink<br />things, I am not afraid<br />of any challenge!</>,
  },
  'Schutzprofil generieren': {
    de: 'Schutzprofil generieren',
    fr: 'Générer le profil de protection',
    en: 'Generate protection profile',
  },
  Tag: {
    de: 'Tag',
    fr: 'Jour',
    en: 'Day',
  },
  Monat: {
    de: 'Monat',
    fr: 'Mois',
    en: 'Month',
  },
  'dateformat:YYYY': {
    de: 'YYYY',
    fr: 'YYYY',
    en: 'YYYY',
  },
  'dateformat:DD': {
    de: 'DD',
    fr: 'DD',
    en: 'DD',
  },
  Jahre: {
    de: 'Jahre',
    fr: 'ans',
    en: 'Years',
  },
  'In die Zukunft schauen': {
    de: 'In die Zukunft schauen',
    fr: 'Se tourner vers l’avenir',
    en: 'Looking to the future',
  },
  'Dein persönlicher Schutzplan': {
    de: 'Dein persönlicher Schutzplan',
    fr: 'Ton plan personnel',
    en: 'Your personal protection plan',
  },
  '€ p. Monat': {
    de: '€ p. Monat',
    fr: '€ par mois',
    en: '€ per month',
  },
  'Vertrag abschließen': {
    de: 'Vertrag abschließen',
    fr: 'Conclure le contrat',
    en: 'Concluding a contract',
  },
  'Auf Basis meiner Empfehlung ergibt sich für Dich eine voraussichtliche monatliche Zahlung in Höhe von 55 Euro. Dieser Beitrag wird von den folgenden drei Faktoren bestimmt. Du kannst diese auf Basis Deiner persönlichen Vorstellungen anpassen.': {
    de: 'Auf Basis meiner Empfehlung ergibt sich für Dich eine voraussichtliche monatliche Zahlung in Höhe von 55 Euro. Dieser Beitrag wird von den folgenden drei Faktoren bestimmt. Du kannst diese auf Basis Deiner persönlichen Vorstellungen anpassen.',
    fr: 'Sur la base de ma recommandation, ton paiement mensuel devrait être de 55 euros. Ce montant est déterminé par les trois facteurs suivants. Tu peux les ajuster en fonction de tes préférences personnelles.',
    en: 'Based on my recommendation, your expected monthly payment will be 55 euros. This contribution is determined by the following three factors. You can adjust these based on your personal preferences.',
  },
  'Mehr über die Absicherung erfahren': {
    de: 'Mehr über die Absicherung erfahren',
    fr: 'En savoir plus sur la couverture',
    en: 'Learn more about coverage',
  },
  '55 € p. Monat': {
    de: '55 € p. Monat',
    fr: '55 € par mois',
    en: '€55 per month',
  },
  '2000€': {
    de: '2000€',
    fr: '2 000 €',
    en: '2000',
  },
  'Welchen Beitrag Du wählen solltest': {
    de: 'Welchen Beitrag Du wählen solltest',
    fr: 'Quel montant tu devrais choisir',
    en: 'Which contribution you should choose',
  },
  'Wie hoch soll die monatliche Zahlung sein? Als Faustregel gilt 60% - 80% von Deinem heutigen Nettoeinkommen.': {
    de: 'Wie hoch soll die monatliche Zahlung sein? Als Faustregel gilt 60% - 80% von Deinem heutigen Nettoeinkommen.',
    fr: 'Quel devrait être le montant du paiement mensuel ? En règle générale, 60 à 80 % de ton revenu net actuel.',
    en: 'How high should the monthly payment be? As a rule of thumb, 60% - 80% of your current net income.',
  },
  'Was Du außerdem beachten solltest': {
    de: 'Was Du außerdem beachten solltest',
    fr: 'Ce que tu devrais également prendre en compte',
    en: 'What you should also bear in mind',
  },
  '67 Jahre': {
    de: '67 Jahre',
    fr: '67 ans',
    en: '67 years',
  },
  'Jährliche Erhöhung': {
    de: 'Jährliche Erhöhung',
    fr: 'Augmentation annuelle',
    en: 'Annual increase',
  },
  Produktunterlagen: {
    de: 'Produktunterlagen',
    fr: 'Documentation sur le produit',
    en: 'Product documentation',
  },
  'Kündigungsschreiben für bestehenden Versicherer': {
    de: 'Kündigungsschreiben für bestehenden Versicherer',
    fr: 'Lettre de résiliation pour l’assureur existant',
    en: 'Cancellation letter for existing insurer',
  },
  'Vermittlerinformationen (Art. 45 VAG)': {
    de: 'Vermittlerinformationen (Art. 45 VAG)',
    fr: 'Informations sur l’intermédiaire (art. 45 LSA)',
    en: 'Broker information (Art. 45 ISA)',
  },
  'Ich bin Alexis, Dein persönlicher Schutzengel.': {
    de: 'Ich bin Alexis, Dein persönlicher Schutzengel. ',
    fr: 'Je suis Alexis, ton ange gardien.',
    en: 'I am Alexis, your personal guardian angel.',
  },
  'Damit die schönen Seiten in Deinem Leben überwiegen, kümmere ich mich um die Risiken.': {
    de: 'Damit die schönen Seiten in Deinem Leben überwiegen, kümmere ich mich um die Risiken.',
    fr: 'Je m’occupe de tous les risques pour garantir que les bons moments prédominent dans ta vie.',
    en: 'So that you can go ahead with making beautiful memories, I take care of all the risks.',
  },
  'Dafür will ich mit Dir Deinen persönlichen Lebensplan entwickeln, dieser wird auf Deine Präferenzen abgestimmt. Dieser Service ist für Dich kostenfrei und dauert nur einige Minuten!': {
    de: 'Dafür will ich mit Dir Deinen persönlichen Lebensplan entwickeln, dieser wird auf Deine Präferenzen abgestimmt. Dieser Service ist für Dich kostenfrei und dauert nur einige Minuten!',
    fr: 'Pour cela, je souhaite élaborer avec toi ton plan de vie personnel, qui sera adapté à tes préférences. Ce service est gratuit et ne prend que quelques minutes !',
    en: 'To do this I would like to work on your personal life plan with you, which will be tailored to your preferences. This service is free of charge and only takes a few minutes!',
  },
  'Um Dich optimal beschützen zu können, werde ich Dir gleich einige Fragen stellen, um zu verstehen worauf Du in Deinem Leben besonderen Wert legst.': {
    de: 'Um Dich optimal beschützen zu können, werde ich Dir gleich einige Fragen stellen, um zu verstehen worauf Du in Deinem Leben besonderen Wert legst.',
    fr: 'Afin de pouvoir te protéger au mieux, je te poserai quelques questions pour comprendre ce qui est le plus important pour toi dans la vie.',
    en: 'In order to be able to protect you in the best possible way, I’ll ask you a few questions in order to understand what you value most in your life.',
  },
  'Unter dem Motto “Zeichne Dein Leben” kannst Du auf den folgenden Seiten Deine persönliche Situation erfassen. Klicke dazu einfach auf die Antwortmöglichkeiten. Ich erledige den Rest!': {
    de: 'Unter dem Motto “Zeichne Dein Leben” kannst Du auf den folgenden Seiten Deine persönliche Situation erfassen. Klicke dazu einfach auf die Antwortmöglichkeiten. Ich erledige den Rest!',
    fr: 'Nous allons résumer ta situation personnelle sur les pages suivantes de manière similaire au principe « dessine ta vie ». Il suffit de cliquer sur les options de réponse. Je m’occupe du reste !',
    en: 'Under the motto “Write your own life”, you can record your personal situation on the following pages. Simply click on the answer options. I’ll do the rest!',
  },
  'Loslegen!': {
    de: 'Loslegen!',
    fr: 'C’est parti !',
    en: 'Get started!',
  },
  'Hallo!': {
    de: 'Hallo!',
    fr: 'Bonjour !',
    en: 'Hello!',
  },
  'Rufe Deinen persönlichen Schutzengel, der Dich auf Deiner Journey begleiten wird.': {
    de: 'Rufe Deinen persönlichen Schutzengel, der Dich auf Deiner Journey begleiten wird.',
    fr: 'Appelle ton ange gardien personnel qui t’accompagnera dans ton parcours.',
    en: 'Call your personal guardian angel who will accompany you on your journey.',
  },
  'Rufe Alexis': {
    de: 'Rufe Alexis',
    fr: 'Appelle Alexis',
    en: 'Call Alexis',
  },
  'Zurück zur Übersicht': {
    de: 'Zurück zur Übersicht',
    fr: 'Retour vers la vue d’ensemble',
    en: 'Back to overview',
  },
  Weiter: {
    de: 'Weiter',
    fr: 'Suivant',
    en: 'Next',
  },
  Zurück: {
    de: 'Zurück',
    fr: 'Retour',
    en: 'Back',
  },
  'Diese Absicherung brauche ich nicht': {
    de: 'Diese Absicherung brauche ich nicht',
    fr: 'Je n’ai pas besoin de cette couverture',
    en: 'I do not need this protection',
  },
  'Die Berufsunfähigkeitsversicherung': {
    de: 'Die Berufsunfähigkeitsversicherung',
    fr: 'L’assurance invalidité professionnelle',
    en: 'Occupational disability insurance',
  },
  'Zum Schutzplan': {
    de: 'Zum Schutzplan',
    fr: 'Voir le plan de protection',
    en: 'About the protection plan',
  },
  'Deine Arbeitskraft ist Deine wichtigste Ressource. Wenn diese ausfällt, kann Dich dies schnell in den finanziellen Ruin treiben.': {
    de: 'Deine Arbeitskraft ist Deine wichtigste Ressource. Wenn diese ausfällt, kann Dich dies schnell in den finanziellen Ruin treiben.',
    fr: 'Ta capacité à travailler est ta ressource la plus importante. Sans elle, tu risques d’arriver rapidement à la ruine financière.',
    en: 'Your ability to work is your most important resource. If this fails, it can quickly drive you into financial ruin.',
  },
  'Deine Arbeitskraft ist wertvoll, ich habe Dir bereits errechnet Du wirst über Dein Leben über 1,5 Millionen Euro verdienen. Die Berufsunfähigkeitsversicherung hilft Dir dieses Geld abzusichern.': {
    de: 'Deine Arbeitskraft ist wertvoll, ich habe Dir bereits errechnet Du wirst über Dein Leben über 1,5 Millionen Euro verdienen. Die Berufsunfähigkeitsversicherung hilft Dir dieses Geld abzusichern.',
    fr: 'Ta capacité à travailler est précieuse : j’ai déjà calculé que tu gagneras plus de 1,5 million d’euros au cours de ta vie. L’assurance invalidité professionnelle t’aide à garantir que tu toucheras à cet argent.',
    en: 'Your ability to work is valuable, I have already calculated that you will earn over 1.5 million euros over your lifetime. Occupational disability insurance helps you to secure this money.',
  },
  'Sie zahlt Dir einen monatlichen Betrag, wenn Du Deinen Beruf nicht mehr ausüben kannst. Der Staat zahlt Dir im Fall, dass Du nicht mehr arbeiten kannst nur 1/4 bis maximal die Hälfte Deines Nettoeinkommens und das auch nur in sehr bestimmten Fällen!': {
    de: 'Sie zahlt Dir einen monatlichen Betrag, wenn Du Deinen Beruf nicht mehr ausüben kannst. Der Staat zahlt Dir im Fall, dass Du nicht mehr arbeiten kannst nur 1/4 bis maximal die Hälfte Deines Nettoeinkommens und das auch nur in sehr bestimmten Fällen!',
    fr: 'Elle te versera un montant mensuel si tu n’es plus en mesure d’exercer ta profession. Si tu ne peux plus travailler, l’État ne te versera qu’entre un quart et la moitié de ton revenu net au maximum, et seulement dans des cas très précis !',
    en: 'It pays you a monthly amount if you can no longer work. If you can no longer work, the state will only pay you 1/4 to a maximum of half of your net income, and only in very specific cases!',
  },
  'Absicherung Deiner Arbeitskraft': {
    de: 'Absicherung Deiner Arbeitskraft',
    fr: 'Assurer votre capacité à travailler',
    en: 'Safeguarding your labour force',
  },
  'Weniger Versicherungen ansehen': {
    de: 'Weniger Versicherungen ansehen',
    fr: 'Afficher moins d\'assurances',
    en: 'View fewer insurance policies',
  },
  'Weitere Versicherungen ansehen': {
    de: 'Weitere Versicherungen ansehen',
    fr: 'Afficher plus d\'assurances',
    en: 'View more insurance policies',
  },
  'Monatliche Auszahlung': {
    de: 'Monatliche Auszahlung',
    fr: 'Versement mensuel',
    en: 'Monthly payment',
  },
  'Ende Deiner Absicherung': {
    de: 'Ende Deiner Absicherung',
    fr: 'Fin de votre assurance',
    en: 'End of your coverage',
  },
  'Sachen//und Personen': {
    de: <>Sachen und<br />Personen</>,
    fr: <>Biens et<br />personnes</>,
    en: <>Property<br />and Life</>,
  },
  Zukunft: {
    de: 'Zukunft',
    fr: 'Avenir',
    en: 'Future',
  },
  'Deine//Liebsten': {
    de: <>Deine<br />Liebsten</>,
    fr: 'Vos proches',
    en: <>Your<br />Loved ones</>,
  },
  'Weitere//ansehen': {
    de: <>Weitere<br />ansehen</>,
    fr: <>Afficher<br />plus</>,
    en: 'See More',
  },
};

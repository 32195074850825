import gsap from 'gsap';
import React, {
  useRef,
} from 'react';
import { Box } from 'theme-ui';
import { ScrollBar } from '../core/ScrollBar';

export const Page = ({ children }) => {
  const contentRef = useRef(null);
  const containerRef = useRef(null);

  const scrollArtifacts = amount => {
    gsap.to('#clouds svg', { marginTop: `${amount}px`, duration: 0.3 });
    gsap.to('#angel', { marginTop: `${amount}px`, duration: 0.3 });
  };

  return (
    <Box
      as="section"
      variant="styles.page"
      ref={containerRef}
    >
      <Box ref={contentRef} className="pageContent">
        {children}
      </Box>

      <ScrollBar
        id="pageScrollBar"
        scrollerRef={containerRef}
        contentRef={contentRef}
        onScroll={e => scrollArtifacts(-1*e.scroller.scrollTop)}
      />
    </Box>
  );
};

import { Box, Flex } from 'theme-ui';
import { useLocation } from 'react-router-dom';
import gsap from 'gsap/gsap-core';
import React, { useEffect } from 'react';
import { Bubbles } from '../components/layout/Bubbles';
import { steps, chatDelay } from '../config';

import { Options } from '../components/layout/Options';
import { CTAButton } from '../components/core/themed';
import { Link } from '../components/core/router/Link';
import { NavigationBottom } from '../components/core/NavigationBottom';
import { getPropertiesLinkNextSection } from '../hooks/usePropertiesLink';

import __ from '../hooks/useI18n';

// eslint-disable-next-line no-unused-vars
function shieldStyle(inActive, defaultFill) {
  return {
    // opacity: inActive ? '0.3 !important' :1,
    // opacity does not work with SAFARI, so have to use fill
    ...(inActive? {
      fill: '#9AB2C5',
    } :{
      fill: 'primary', // SAFARI BUG: defaultFill is not found by svg url lookup on chnaging back... :(
      // fill: defaultFill,
    }),
  };
}

const styles = {
  options: (params, activeShieldFill) => ({
    variant: 'flex.col',
    alignItems: 'flex-end',
    height: (theme) => theme.sizes.getRelativeHeight(0.805),

    '#options': {
      pt: '28vh',
      pb: '11vh',
      pl: 0,
      flexWrap: 'wrap',
      overflow: 'visible !important',
      justifyContent: 'center',
      rowGap: 0,

      '.hover-item:nth-child(2) .icon #shield': shieldStyle(!+params.work, activeShieldFill[0]),
      '.hover-item:nth-child(3) .icon #shield': shieldStyle(!+params.health, activeShieldFill[1]),
      '.hover-item:nth-child(4) .icon #shield': shieldStyle(!+params.people, activeShieldFill[2]),
      '.hover-item:nth-child(5) .icon #shield': shieldStyle(!+params.future, activeShieldFill[3]),
      '.hover-item:nth-child(6)': {
        pt: '2vh',
      },
      '.hover-item:nth-child(7) .icon #shield': shieldStyle(!+params.family, activeShieldFill[4]),
      '.hover-item:nth-child(8) .icon #shield': shieldStyle(!+params.residence, activeShieldFill[5]),
      '.hover-item:nth-child(9) .icon #shield': shieldStyle(!+params.more, activeShieldFill[6]),
      '.item': {
        flex: '0 0 auto',

        'button, a': {
          '.icon': {
            width: '15vh',
            height: '13vh',
            justifyContent: 'center',
            zIndex: 9,

            'svg, img': {
              maxHeight: '13vh',
            },
            '&:after': {
              content: '""',
              display: 'none',
            },
          },
        },
        '.itemIconText': {
        },
      },
      '.hover-item:nth-child(2), .hover-item:nth-child(3), .hover-item:nth-child(4), .hover-item:nth-child(5), .hover-item:nth-child(7), .hover-item:nth-child(8), .hover-item:nth-child(9)': {
        position: 'relative',

        '&:hover': {
          'svg, img, .itemText': {
            transform: 'scale(1.2) !important',
            // filter: 'drop-shadow( 3px -3px 2px rgba(50, 50, 50, .7))',
          },
        },
        '& .itemText': {
          transition: 'transform 0.2s',
          transformOrigin: '30% bottom',

          color: '#f1f1f1',
          position: 'absolute',
          bottom: '-15%',
          left: '-24%',
          width: 'fullSize',
          height: 'fullSize',
          fontSize: 's',
          zIndex: 10,
        },
      },
    },
    '.navigation': {
      display: 'none',
    },
  }),
  ctaButtons: {
    variant: 'flex.center',
  },
};

export const Protection = ({ match }) => {
  const { pathname } = useLocation();
  const [activeShieldFill, setActiveShieldFill] = React.useState([]);

  useEffect(() => {
    setActiveShieldFill(
      Array.from(document.querySelectorAll('#options .hover-item .icon #shield'))
        .map(item => gsap.getProperty(item, 'fill')),
    );
  }, []);

  return (
    <Flex variant="flex.colCenter">
      <Bubbles texts={steps.protection.bubbles} sx={styles.bubbles} />

      {steps.protection.options && !steps.protection.optionComponent && <Options
        {...steps.protection}
        items={steps.protection.options}
        sx={styles.options(match.params, activeShieldFill)}
        delay={steps.protection.bubbles.length*chatDelay}
        match={match}
        steps={steps.plans}
      />}

      <Box sx={styles.ctaButtons} className="button-container">
        <CTAButton>
          <Link to={getPropertiesLinkNextSection({ pathname, root: '/final' })}>
            {__('Zum Schutzplan')}
          </Link>
        </CTAButton>
      </Box>

      <NavigationBottom />

    </Flex>
  );
};

import React from 'react';

import { Balloon } from '../custom/Balloon';

export const DateValue = ({ value }) => {
  const years = new Date().getFullYear() - new Date(value).getFullYear();
  return (
    <Balloon>{`${years}`}</Balloon>
  );
};

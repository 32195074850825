import { Box } from 'theme-ui';
import { useLocation } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { MotionPathPlugin } from 'gsap/all';
import { ReactComponent as CloudsIcon } from './clouds.svg';

const styles = {
  container: (pathname) => {
    const baseStyles = ({
      transition: 'all 0.5s ease-out',
      variant: 'styles.fullWidth',
      position: 'absolute',
      zIndex: 'cloud',
      bottom: '13px',
      height: '60vh',
      overflow: 'visible',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'flex-end',

      '> svg': {
        width: 'auto',
        height: '100%',
        overflow: 'visible',
        transform: 'translate(-1vh, 10vh)',
      },
    });

    return ((p) => {
      const root = `${p}`.match((/^\/([^/]+)/));
      switch (root && root[0]) {
      case undefined:
      case 'undefined':
      case '':
      case '/':
      case '/en':
      case '/de':
      case '/fr':
        return {
          ...baseStyles,
          bottom: '-15vh',
        };
      case '/intro':
        return {
          ...baseStyles,
          bottom: '1vh',
        };
      case '/protection':
        return {
          ...baseStyles,
          bottom: `${p}`.match(/^(\/\w+){9}$/) ? '-29vh' : '-33vh',
        };
      case '/final':
        return {
          ...baseStyles,
          bottom: '28vh',
          justifyContent: 'flex-start',
          '.bottomCloud': {
            display: 'flex',
            flex: '1 0 40vh',
          },
        };
      case '/plans':
        return {
          ...baseStyles,
          opacity: 0,
          bottom: '-17vh',
        };
      default:
        return {
          ...baseStyles,
          bottom: '-17vh',
        };
      }
    })(pathname);
  },

  bottomCloud: {
    bg: 'background',
    display: 'flex',
    overflow: 'hidden',
    flex: '0 1 0',
  },

  trace: {
    position: 'absolute',
    svg: {
      opacity: 0,
    },
  },
};

export const Clouds = () => {
  const location = useLocation();
  const containerRef = useRef(null);

  gsap.registerPlugin(MotionPathPlugin);

  useEffect(() => {
    gsap.to(`.${containerRef.current.className} #cloud1`, {
      motionPath: {
        path: '#cloudmotion1',
        align: '#cloudmotion1',
        alignOrigin: [1, 1],
      },
      duration: 5,
      repeat: -1,
      ease: 'none',
    });

    gsap.to(`.${containerRef.current.className} #cloud2`, {
      motionPath: {
        path: '#cloudmotion2',
        align: '#cloudmotion2',
        alignOrigin: [1, 1],
      },
      duration: 5,
      repeat: -1,
      delay: -1.5,
      ease: 'none',
    });
  }, [containerRef]);

  return (
    <>
      <Box id="clouds" sx={styles.container(location.pathname)} ref={containerRef}>
        <CloudsIcon />
        <Box sx={styles.bottomCloud} className="bottomCloud" />
      </Box>
    </>
  );
};

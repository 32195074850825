import gsap from 'gsap';
import { Global } from '@emotion/react';
import { useLayoutEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button } from 'theme-ui';
import { ReactComponent as IconX } from '../../static/images/x.svg';
import { ScrollBar } from '../core/ScrollBar';
import { ProductButtons } from '../../pages/ProductDetails';

const styles = {
  container: {
    variant: 'styles.page',
    position: 'absolute',
    top: 0,
    p: 0,
    height: 'browserHeight',
    zIndex: 'popup',
    border: 'none',
    backgroundColor: 'transparent',
    display: 'block',
    opacity: 1,
    '.pageContent': {
      pt: 0,
    },
  },
  close: {
    position: 'fixed',
    top: '7',
    right: 7,
    width: 'max-content',
    zIndex: 'popup',
  },
  buttons: {
    position: 'fixed',
    zIndex: 'popup',
  },
  content: {
    '& > :first-child': {
      pt: '8',
    },
  },
};

export const SlideLeft = ({ match, children, footer: footerDefault }) => {
  const { go } = useHistory();
  const contentRef = useRef(null);
  const containerRef = useRef(null);

  const footer = footerDefault || <ProductButtons match={match} sx={styles.buttons} className="overlay-button" />;

  useLayoutEffect(() => {
    gsap.from(containerRef.current, {
      x: '100vh',
      opacity: 0,
    });
  }, [containerRef]);

  return (
    <>
      <Global
        styles={() => ({
          main: {
            'h1, button': {
              opacity: 0,
              transform: 'translateX(-100vh)',
            },
            '#pageScrollBar': {
              display: 'none',
            },
            'dialog, dialog ~ .overlay-button': {
              'h1, button': {
                opacity: 1,
                transform: 'none',
              },
            },
          },
        })}
      />
      <Box sx={styles.container} ref={containerRef} as="dialog">
        <Box ref={contentRef} sx={styles.content} className="pageContent">
          {children}
        </Box>
      </Box>

      <ScrollBar
        id="overlayScrollBar"
        scrollerRef={containerRef}
        contentRef={contentRef}
      />

      <Box sx={styles.close} className="overlay-button">
        <Button onClick={() => go(-1)}>
          <IconX />
        </Button>
      </Box>

      {footer}
    </>
  );
};

import gsap from 'gsap';
import { useLayoutEffect, useRef } from 'react';
import { Box } from 'theme-ui';
import { Title } from '../core/themed';

const styles = {
  container: {
    variant: 'flex.center',
    height: '15.5vh',
    svg: {
      height: '100%',
      width: 'auto',
      objectFit: 'contain',
    },
  },
  title: {
    position: 'absolute',
    zIndex: 10,
    color: 'background',
    pb: '6vh',
    pt: 0,
    opacity: 0.5,
    fontSize: 'xxl',
    fontWeight: 900,
  },
};

export const Balloon = ({ children }) => {
  const containerRef = useRef(null);

  useLayoutEffect(() => {
    gsap.set(`#${containerRef.current.id} #line2`, {
      opacity: 0,
    });
    gsap
      .to(`#${containerRef.current.id}`, {
        y: -10,
        duration: 2,
        repeat: -1,
        yoyo: 1,
      });
    gsap.to(`#${containerRef.current.id} #line`, {
      morphSVG: `#${containerRef.current.id} #line2`,
      repeat: -1,
      yoyo: 1,
      duration: 1,
    });
  }, [containerRef]);
  return (
    <Box sx={styles.container} ref={containerRef} id="age-ballon">
      <Title sx={styles.title}>{children}</Title>
      <svg width="87" height="185" viewBox="0 0 87 185" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M40.5256,96.899 C36.7166,100.253 46.3334,109.894 46,114 C45.5018,120.311 31.4145,124.096 35,130 C36.5539,132.6 38,133 41,136 C44,139 46.8921031,148.323691 46,151 C44,157 38.3932,160.155 38,164 C37.6067,167.846 39,171 44,175" id="line2" stroke="#3F3D56" />
        <path id="line" d="M44.5256 105.899C40.7166 109.253 38.391 113.045 38.0576 117.151C37.5594 123.462 43.9568 128.414 47.5423 134.318C49.0962 136.918 49.7995 139.689 49.6101 142.464C49.4207 145.24 48.3425 147.963 46.44 150.47C44.6904 152.744 42.1955 155.002 42.5172 157.52C42.7015 159.057 43.9255 160.455 44.9101 161.868C47.3267 165.409 48.3811 169.259 47.9879 173.104C47.5946 176.95 45.7647 180.683 42.6466 184" stroke="#3F3D56" strokeWidth="2" strokeMiterlimit="10" />
        <path d="M47.4896 108.654H37.1147L38.9326 102.432H45.1544L47.4896 108.654Z" fill="#FF8E72" />
        <path d="M45.1544 102.691H38.9325L34.3005 99.6143H49.7864L45.1544 102.691Z" fill="#3F3D56" />
        <path d="M79.9965 48.2362C79.9965 69.9069 62.9828 101.652 41.9983 101.652C21.0138 101.652 4 69.9033 4 48.2362C4 37.8301 8.00356 27.8502 15.1299 20.492C22.2563 13.1338 31.9218 9 42 9C52.0782 9 61.7437 13.1338 68.8701 20.492C75.9964 27.8502 80 37.8301 80 48.2362H79.9965Z" fill="#FF8E72" />
        <path opacity="0.5" d="M26.7282 18.1143C26.7282 18.1143 10.7037 27.0961 8.41394 45.0598L26.7282 18.1143Z" fill="white" />
        <path opacity="0.5" d="M75.2816 66.4219C75.2816 66.4219 59.2571 75.4037 56.9673 93.3674L75.2816 66.4219Z" fill="white" />
      </svg>
    </Box>

  );
};

// import { useLocation } from 'react-router-dom';
import { parse } from 'query-string';

// TODO: handle all cases (string vs string[]) + add types
// export const useQueryParam = (key) => {
//   const { search } = useLocation();
//   return `${parse(search)[key]||''}`;
// };

export const getQueryParam = (key) => {
  const { search } = window.location;
  return `${parse(search)[key] || ''}`;
};

export const getRootPath = () => {
  const { pathname } = window.location;
  return `${pathname}`.replace(/^\//, '').split('/')[0];
};

export default getQueryParam('lang');

import { Switch, Route } from 'react-router-dom';

import { SlideLeft } from './components/layout/SlideLeft';
import { ProtectionDetails } from './pages/ProtectionDetails';
import { FinalDetails } from './pages/FinalDetails';
import { urlParams } from './Pages';
import { ProductButtons } from './pages/ProductDetails';
import cloudButton from './components/layout/cloud-button.png';

const styles = {
  finalButtons: {
    // TODO: move into theme-ui
    backgroundImage: `url(${cloudButton})`,
    backgroundSize: '100% 100%',
    backgroundPosition: 'bottom center',
    backgroundRepeat: 'no-repeat',
    zIndex: 'float',
    '& > *': {
      zIndex: 'float',
    },
  },
};

export const OverLays = () => (
  <Switch>
    <Route path={`/protection${urlParams.protection}/:id`} component={(props) => <SlideLeft {...props}><ProtectionDetails {...props} /></SlideLeft>} />
    <Route path={`/final${urlParams.final}/:id`} component={(props) => <SlideLeft {...props} footer={<ProductButtons {...props} sx={styles.finalButtons} className="overlay-button" />}><FinalDetails {...props} /></SlideLeft>} />
  </Switch>
);

// import gsap from 'gsap';
// import CustomEase from 'gsap/CustomEase';
// import SplitText from 'gsap/SplitText';
import React, {
// useEffect, useRef
} from 'react';
import { Button, Image as ThemedImage } from 'theme-ui';

export { Button } from 'theme-ui';

// React.forwardRef(
// , ref // TODO: LATER
// const ref = useRef(null);
// gsap.registerPlugin(CustomEase, SplitText);

// useEffect(() => {
//   if (active) {
//   // eslint-disable-next-line no-unused-vars
//     const splitText = new SplitText(ref.current, {
//       type: 'chars,words', charsClass: 'char', reduceWhiteSpace: false, wordsClass: 'word',
//     });

//     gsap.timeline({
//       repeat: -1,
//       delay: animationDelay,
//     })

//       // .to(ref.current, {
//       //   scale: 1.03,
//       //   ease: CustomEase.create('custom', 'M0,0,C0,0,0,1,0.154,1,0.304,1,0.168,0.568,0.33,0.568,0.488,0.568,0.338,1,0.488,1,0.711,1,0.567,0.011,0.71,0,0.976,-0.022,1,0,1,0'),
//       //   duration: 1,
//       // })
//       .to(splitText.chars, {
//         y: -7,
//         // ease: 'back(4)',
//         ease: CustomEase.create('custom', 'M0,0 C0,0.292 0,1 0.5,1 1,1 1,0.278 1,0 '),
//         stagger: 0.05,
//         duration: 0.2,
//       }, '<')
//       .to({}, {
//         duration: 2,
//       });
//   }
//   // NOTE: was not tested, TODO: LATER make sure this works later
//   // ref.current=ref.current;
// }, [ref, active]);
export const CTAButton = ({
  // animationDelay = 2,
  children, active=true, ...props
}) => <Button data-cta="button" variant="cta" {...props} disabled={!active}>{children}</Button>;
// ref={ref}
// );

export const InfoButton = React.forwardRef(({ children, ...props }, ref) => <Button data-cta="info" variant="info" {...props} ref={ref}>{children}</Button>);

export const ImageButton = React.forwardRef(({ children, src, ...props }, ref) => (
  <Button data-cta="image" variant="pure" {...props} ref={ref}>
    <ThemedImage src={src} />
    {children}
  </Button>
));

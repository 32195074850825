import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from 'react-auth-kit';

import { DebugLogProvider } from './components/layout/DevTools';
import { Layout } from './components/layout/Layout';
import { I18NProvider } from './hooks/useI18n';
import { OverLays } from './OverLays';
import { Pages } from './Pages';

function App() {
  return (
    <AuthProvider
      authType="cookie"
      authName="_auth"
      cookieDomain={window.location.hostname}
      cookieSecure={window.location.protocol === 'https:'}
    >
      <Router>
        <DebugLogProvider>
          <I18NProvider>
            <Layout overLays={<OverLays />}>
              <Pages />
            </Layout>
          </I18NProvider>
        </DebugLogProvider>
      </Router>
    </AuthProvider>
  );
}

export default App;

import { Flex, Box, Text } from 'theme-ui';
import React from 'react';

import { Slider } from './Slider';
import Couch from '../../static/images/risk/Couch.svg';
import Astronaut from '../../static/images/risk/Astronaut.svg';

const circleStyles = (color) => ({
  width: '8.3vh',
  height: '8.3vh',
  borderRadius: '50%',
  backgroundColor: color,
  flexShrink: 0,
  'svg, img': {
    width: '100%',
    height: '100%',
  },
});

export const CircleIcon = ({ icon, color, sx }) => <Flex variant="flex.colCenter" sx={theme => ({ ...circleStyles(theme.colors[color]), ...sx })}><img src={icon} alt={color} /></Flex>;

const simpleBubbleStyles = (bgColor) => ({
  background: bgColor,
  borderRadius: 6,
  color: 'primary',
  px: '20px',
  py: '10px',
  whiteSpace: 'nowrap',
});

export const SimpleBubble = ({
  children,
}) => <Flex variant="flex.colCenter" sx={theme => simpleBubbleStyles(theme.colors.background)}>
  {children}
</Flex>;

const simpleBubbleWithArrowStyles = {
  arrow: (bgColor) => ({
    width: 6,
    height: 5,
    clipPath: 'polygon(50% 0%, 0% 100%, 100% 100%)',
    background: bgColor,
    transform: 'translateY(1px)',
  }),
};

export const SimpleBubbleWithArrow = ({
  children, sx,
}) => <Flex sx={{ ...simpleBubbleWithArrowStyles.box, ...sx }} variant="flex.colCenter">
  <Box sx={theme => simpleBubbleWithArrowStyles.arrow(theme.colors.background)} />
  <SimpleBubble>{children}</SimpleBubble>
</Flex>;

const getArrowTransform = (index, data) => {
  switch (index) {
  case 1:
    return -50;
  case data.length:
    return 50;
  default:
    return 0;
  }
};

const thumbStyles = {
  thumb: (color) => ({
    'span, p': {
      margin: 0,
      padding: 0,
    },
    background: color,
  }),
  text: {
    fontWeight: 'medium',
  },
  bubble: (index, data) => ({
    position: 'absolute',
    fontSize: 'm',
    width: 11,
    top: '150%',
    'div:first-of-type': {
      transform: `translate(${getArrowTransform(index, data)}px, 1px)`,
    },
    transform: `translateX(${-getArrowTransform(index, data)}px)`,
  }),
};

export function ThumbComponentWithValue({ index, data, ...props }) {
  const TextComponent = data[index-1].text;
  return (
    <Flex variant="flex.colCenter" sx={theme => thumbStyles.thumb(theme.colors.riskGradients[index-1])} {...props}>
      <Text>{index}</Text>
      <SimpleBubbleWithArrow
        sx={thumbStyles.bubble(index, data)}
      ><TextComponent /></SimpleBubbleWithArrow>
    </Flex>
  );
}

const styles = {
  box: {
    height: 12,
  },
  leftCircle: {
    transform: 'translateX(0.8vh)',
    padding: '1vh',
    zIndex: 1,
  },
  rightCircle: {
    transform: 'translateX(-0.8vh)',
    overflow: 'hidden',
    'img, svg': {
      transform: 'scale(0.9) translateY(0.8vh)',
    },
  },
};

export const AngelSlider = ({ sliderIndex, setSliderIndex, data }) => {
  const onSliderMoved = (index) => {
    if (index === data.length + 1) return;
    if (index === 0) return;
    setSliderIndex(index);
  };

  return <Flex sx={styles.box}>
    <Flex variant="flex.rowCenter">
      <CircleIcon
        sx={styles.leftCircle}
        color="safe"
        icon={Couch}
      />

      <Slider
        ThumbComponent={props => <ThumbComponentWithValue index={sliderIndex} data={data} {...props} />}
        getAriaLabel={(index) => `option ${index+1} selected`}
        defaultValue={0}
        step={1}
        min={0}
        max={data.length + 1}
        value={sliderIndex}
        onChange={(_, value) => onSliderMoved(value)}
      />

      <CircleIcon
        sx={styles.rightCircle}
        color="dangerous"
        icon={Astronaut}
      />
    </Flex>
  </Flex>;
};

import {
  Box, Button, useThemeUI,
} from 'theme-ui';
import React from 'react';
import { BsArrowsFullscreen } from 'react-icons/bs';
import { AiOutlineFullscreenExit, AiOutlineInfoCircle } from 'react-icons/ai';
import { GrRefresh } from 'react-icons/gr';
import { Global } from '@emotion/react';
import { Paragraph } from '../core/themed';
import useFullscreenStatus from '../../hooks/useFullscreenStatus';
import {
  useLanguage, useRotateLanguage,
} from '../../hooks/useI18n';

// const alwaysShowInfo = true;
const alwaysShowInfo = false;

export const styles = {
  devTools: {
    position: 'absolute',
    zIndex: 'popup',
    right: 4,
    bottom: 4,
    borderRadius: 2,
    border: 'thin',
    borderColor: 'gray.2',
    bg: 'gray.1',
    opacity: 0.8,
    p: 2,
    display: alwaysShowInfo ? 'flex' : ['none', 'none', 'flex'],
    flexDirection: 'column',
    width: 'max-content',
    height: 'max-content',
  },
  icon: {
    width: '2em',
    height: '2em',
  },
  info: {
    maxWidth: '550px',
    maxHeight: '500px',
    textAlign: 'left',
    overflow: 'scroll',
  },
};

const DebugLogContext = React.createContext();

export function DebugLogProvider({ children }) {
  const [logs, setLogs] = React.useState([]);
  const addLog = (log) => { console.log(log); setLogs(logs.concat(log)); };

  return (
    <DebugLogContext.Provider value={[logs, addLog]}>
      {children}
    </DebugLogContext.Provider>
  );
}

export const useDebugLog = () => React.useContext(DebugLogContext);

export const DevTools = () => {
  const [isInfoOpen, setInfoOpen] = React.useState(alwaysShowInfo);
  const [logs, addLog] = useDebugLog();
  const { theme } = useThemeUI();
  const lang = useLanguage();

  const rotateLanguage = useRotateLanguage();

  // try {
  const [isFullscreen, setIsFullscreen] = useFullscreenStatus();
  // } catch (e) {
  //   errorMessage = "Fullscreen not supported";
  //   isFullscreen = false;
  //   setIsFullscreen = undefined;
  // }
  const handleExitFullscreen = () => {
    try {
      document.exitFullscreen();
    } catch (e) { addLog(`${e}`); }
  };

  return (
    <>
      <Global
        styles={() => ({
          // DIRTY HACK: workaround to hide netlify overlay
          '[data-netlify-deploy-id]': {
            display: 'none !important',
            border: 'solid 3px orange !important',
          },
        })}
      />
      <Box id="dev-tools" sx={styles.devTools}>
        <Button onClick={rotateLanguage}>
          lang:{lang}
        </Button>

        {!isFullscreen && (
          <Button onClick={setIsFullscreen}>
            <BsArrowsFullscreen style={styles.icon} />
          </Button>
        )}

        {isFullscreen && (
          <Button onClick={handleExitFullscreen}>
            <AiOutlineFullscreenExit style={styles.icon} />
          </Button>
        )}

        <Button onClick={() => window.location.reload()}>
          <GrRefresh style={styles.icon} />
        </Button>

        <Button onClick={() => setInfoOpen(!isInfoOpen)}>
          <AiOutlineInfoCircle style={styles.icon} />
        </Button>
        {isInfoOpen && (
          <Paragraph sx={styles.info}>
            {window.navigator.userAgent}<br />
            breakp: {theme.breakpoints.map(bp => bp.replace(/\.\d+/gi, '')).join('/')}<br />
            res: {window.innerWidth}x{window.innerHeight}
            ({document.body.clientWidth}x{document.body.clientHeight},
            {document.body.offsetWidth}x{document.body.offsetHeight},
            {window.screen.width}x{window.screen.height})<br />

            {logs.map((info, index) => <span key={index}>{info}<br /></span>)}
          </Paragraph>
        )}
      </Box>
    </>
  );
};

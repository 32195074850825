import { Box, Flex } from 'theme-ui';
// import { Link } from 'react-router-dom';
import { Bubbles } from '../components/layout/Bubbles';
import { Options } from '../components/layout/Options';
import { steps, chatDelay } from '../config';

import { useCurrentStepName } from '../hooks/useCurrentStepName';
import { PropertyContainer } from '../components/PropertyContainer';

const styles = {
  bottom: {
    position: 'absolute',
    width: '100%',
    bottom: 0,
    // flex: '1 1 32vh',
    zIndex: 'options',
  },
};

export const Status = ({ match }) => {
  const currentStepName = useCurrentStepName(match);
  const currentStep = steps.status[currentStepName];

  if (!currentStep) return null;

  // console.log('status step', currentStepName, currentStep, match.params);

  return (
    <Flex variant="flex.colCenter">
      <Bubbles texts={currentStep.bubbles} />

      <PropertyContainer match={match} delay={currentStep.bubbles.length*chatDelay} />

      {currentStep.options && !currentStep.optionComponent && <Options
        {...currentStep}
        items={currentStep.options}
        sx={styles.bottom}
        delay={currentStep.bubbles.length*chatDelay}
        match={match}
        additionalRevealAnimation={currentStep.options.length > 4}
      />}

      {currentStep.optionComponent && <Box sx={styles.bottom}><currentStep.optionComponent {...currentStep} /></Box>}
    </Flex>
  );
};

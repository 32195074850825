// import { getDevicePixelRatio } from './getDevicePixelRatio';
const isDev = process.env.NODE_ENV === 'development';

export const hiddenScrollBars = {
  // Safari, Chrome & Opera
  '&::-webkit-scrollbar': {
    display: 'none',
    width: '0px',
    height: '0px',
    bg: 'transparent',
    // ...(isDev ? {
    //   display: 'initial',
    //   width: '20px',
    //   bg: 'pink',
    // }: {}),
  },
  '&::-webkit-scrollbar-thumb': {
    bg: 'primary',
    // ...(isDev ? {
    //   bg: 'red',
    // }: {}),
  },
  // others:
  '&': {
    '-ms-overflow-style': 'none', /* IE and Edge */
    scrollbarWidth: 'none', /* Firefox */
  },
};

const getRelativeHeight = (rel, bodySizes) => `${Math.round(rel*bodySizes.height)}px`;

// ideal proportion matches ipad mini 75vh, however ipad pro 11 is 69vh, which would fit in
export function getTheme(bodySizes) {
  const idealProportion = 0.71;
  const sizes = {
    0: 0,
    1: '0.1675vh', // '2px', // 2 ^ 1
    2: '0.335vh', // '4px', // 2 ^ 2
    3: '0.67vh', // '8px', // 2 ^ 3
    4: '1.34vh', // '16px', // 2 ^ 4
    5: '2.01vh', // '24px', // WARN, index 5 is custom, preferred by our designers
    6: '2.68vh', // '32px', // WARN, index 6 === 2 ^ 5
    7: '4.02vh', // '48px',
    8: '5.36vh', // '64px', // WARN, index 8 === 2 ^ 6
    9: '8.375vh', // '100px',
    10: '10.72vh', // '128px',
    11: '21.44vh', // '256px',
    12: '42.88vh', // '512px',

    icon: '24px',

    fullSize: '100%',
    browserWidth: '100vw',
    browserHeight: `${bodySizes.height}px`,
    getRelativeHeight: rel => getRelativeHeight(rel, bodySizes),

    ipadProMaxWidth: `${idealProportion*100}vh`, // shall be very strict, to match the tablet bg
    tabletMaxWidth: '75vh', // shall be very permissive, with the biggest possible resolution

    headerHeight: '8vh',
    // pageWithoutHeaderHeight: '92vh', // 100 - headerHeight

    pageMargin: '6.25rem',
    getScrollBarHeight: (range = 1) => getRelativeHeight(range*0.333, bodySizes),
    idealProportion,
  };

  const zIndices = {
    header: 1002,
    nav: 1005,
    angel: 1003,
    content: 1,
    cloud: 0,
    popup: 9999,
    float: 10000,
    bubbles: 11,
    properties: 10,
    options: 11,
  };

  const fontSizes = {
    // xxxs: '0.625rem', // not in use
    // xxs: '0.75rem', // not in use
    xs: '0.875rem', // not in use
    s: '1rem', // = 16px
    m: '1.3125rem', // = 21px
    l: '1.75rem', // = 28px
    xl: '1.875rem', // = 30px
    xxl: '2.125rem', // = 34px
  };

  const fonts = {
    body: 'Manrope, sans-serif',
    heading: 'Manrope, sans-serif',
    bold: 'Manrope, sans-serif',
  };

  const fontWeights= {
    body: 500,
    normal: 500, // duplicate and alias of body
    s: 500, // needed when using fontSize: s
    medium: 600,
    heading: 700,
    bold: 700,
  };

  const lineHeights= {
    body: 'normal',
    // heading: 1.15,
  };

  // const primaryRgba = (opacity = 1) => `rgba(0, 189, 89,${opacity})`;

  const colors= {
    text: '#000',
    background: '#fff',
    primary: '#05415A', // HuP blue
    green1: '#3A7D44', // green
    secondary: '#79C99E', // lightgreen
    tertiary: '#CFE3FF', // airblue
    border: '#b2b2b2',
    // muted: '#f2f4f6',
    // mutedDark: 'rgba(92, 110, 142, 0.5)',
    warning: '#ff0000', // red
    // black50Percent: 'rgba(0, 0, 0, 0.5)',
    // white50Percent: 'rgba(256, 256, 256, 0.5)',
    gray: {
      0: 'rgb(51, 51, 51)',
      1: 'rgb(126, 126, 126)',
      2: 'rgb(187, 187, 187)',
      3: 'rgb(225, 225, 225)',
      4: 'rgb(246, 246, 246)',
      5: 'rgb(250, 250, 250)',
      6: 'rgb(255, 255, 255)',
    },
    primaryLight: {
      0: 'rgba(5, 65, 90, 0)',
      1: 'rgba(5, 65, 90, 0.1)',
      2: 'rgba(5, 65, 90, 0.2)',
      3: 'rgba(5, 65, 90, 0.3)',
      4: 'rgba(5, 65, 90, 0.4)',
      5: 'rgba(5, 65, 90, 0.5)',
    },
    green1Light: {
      0: 'rgba(58,125,68,0)',
      1: 'rgba(58,125,68,0.1)',
      2: 'rgba(58,125,68,0.2)',
      3: 'rgba(58,125,68,0.3)',
      4: 'rgba(58,125,68,0.4)',
      5: 'rgba(58,125,68,0.5)',
    },
    // primaryDarker: {
    //   0: 'rgba(0, 140, 31, 0)',
    //   1: 'rgba(0, 140, 31, 0.1)',
    //   2: 'rgba(0, 140, 31, 0.3)',
    //   3: 'rgba(0, 140, 31, 0.5)',
    //   4: 'rgba(0, 140, 31, 0.7)',
    //   5: 'rgba(0, 140, 31, 0.9)',
    //   6: 'rgba(0, 140, 31, 1)',
    // },
    // primaryLighter: {
    //   0: primaryRgba(1),
    //   1: primaryRgba(0.9),
    //   2: primaryRgba(0.7),
    //   3: primaryRgba(0.5),
    //   4: primaryRgba(0.3),
    //   5: primaryRgba(0.1),
    //   6: primaryRgba(0),
    // },
    riskGradients: [
      'linear-gradient(90deg, #69B578 0%, #72D5A3 122.62%)',
      'linear-gradient(84.79deg, #69B578 5.34%, #F0E467 116.25%)',
      'linear-gradient(270deg, #F1D371 17.86%, #DBBE74 138.1%)',
      'linear-gradient(90deg, #FDC871 -26.62%, #FF8E72 155.22%)',
      'linear-gradient(90deg, #FFA272 0%, #FF8E72 100%)',
    ],
    safe: '#69B578',
    dangerous: '#FF947A',
  };

  const _buttonStyles = {
    outline: 'none',
    cursor: 'pointer',
    background: 'transparent',
    '&, *': {
      color: 'text',
      fontFamily: 'bold',
      fontWeight: 'bold',
      fontSize: 'm',
    },
    textDecoration: 'none',
    transition: 'all 0.2s ease-out',
    transformOrigin: 'center',

    '& button:disabled, & a:disabled, &:disabled': {
      bg: '#A4BAC2',
      cursor: 'default',
    },
    '&:disabled:hover': {
      textDecoration: 'none',
    },
  };

  const styles = {
    // should be used by Text by default, but doesn't work yet
    // https://github.com/system-ui/theme-ui/issues/1024
    default: {
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
      fontSize: 'm',
      margin: '0 0 8px 0',
      color: 'text',
    },
    animable: {
      transition: 'all 0.5s ease-out',
    },

    // variants built extending text.default with additional props
    small: {
      variant: 'text.default',
      fontSize: 's',
      fontWeight: 's',
    },
    strong: {
      variant: 'text.default',
      fontWeight: 'bold',
      fontSize: 'inherit',
    },
    caps: {
      variant: 'text.default',
      textTransform: 'uppercase',
    },
    antialiased: {
      variant: 'text.default',
      letterSpacing: 'normal',
      textShadow: '#fff 0px 1px 1px',
      webkitTextStroke: '0.45px rgba(0, 0, 0, 0.1)',
      webkitFontSmoothing: 'subpixel-antialiased',
    },
    grayScale: {
      variant: 'text.default',
      filter: 'grayscale(1);', // this is different than text.default with default color, as it impacts not only text, but everything including images inside
    },
    fullWidth: {
      width: '100%',
      maxWidth: 'unset',
      flex: '1 1 auto',
    },
    // used by default by Heading Component
    heading: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading',
      fontSize: 'l',
      letterSpacing: '-0.5px',
      color: 'primary',
    },

    main: {
      variant: 'flex.center',
      maxWidth: ['tabletMaxWidth', 'tabletMaxWidth', 'ipadProMaxWidth'],
      backgroundImage: 'linear-gradient(180deg, #CFE3FF 0%, #DFEAF8 100%)',
      backgroundPosition: 'center top',
      backgroundRepeat: 'no-repeat',
      position: 'relative',
      margin: 'auto',
      // TODO: eval for long pages
      overflow: 'hidden',
      // maxHeight: ['unset', 'unset', 'unset'],
      transform: ['none', 'none', 'scale(0.93)'],
      borderRadius: ['none', 'none', '1rem'],
      border: isDev
        ? ['solid 2px orange', 'solid 2px green', 'solid 2px red']
        :['none', 'none', 'solid 1px black'],
    },
    section: {
      variant: 'styles.fullWidth',
      position: 'relative',
      zIndex: 'content',

      minHeight: 'browserHeight',
      height: 'browserHeight',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      borderBottom: isDev ? 'dotted 1px lightgray' :'initial',
      heigt: 'auto',
      // TODO: eval for long pages
      overflow: 'hidden',
      overflowY: 'scroll',
      ...hiddenScrollBars,
    },
    page: {
      variant: 'styles.section',
      '.pageContent': {
        pt: 'headerHeight',
      },
    },

    /** ***********
     * The following items are due for further evaluation whether we are going to use them in text.* variant or Styled.* to generate the tags
    ************ */

    // default for Styled.*
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
      color: 'text',
    },
    // alias for text.heading variant
    h1: {
      variant: 'text.heading',
      // fontWeight: 'normal',
      fontSize: 'xxl',
    },
    h2: {
      variant: 'text.heading',
      fontWeight: 'normal',
      fontSize: 'xl',
    },
    h3: {
      variant: 'text.heading',
      fontSize: 'l',
      fontWeight: 'normal',
    },
    h4: {
      variant: 'text.heading',
      width: '100%',
      px: 'pageMargin',
      fontSize: 'l',
      textAlign: 'left',
      pt: 5,
      pb: 4,
      fontWeight: 'bold',
    },

    p: {
      color: 'primary',
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
      fontSize: 'm',
      position: 'relative',
      p: 6,
      px: 'pageMargin',
      textAlign: 'left',
    },
    h5: {
      variant: 'styles.p',
      fontSize: 'l',
    },
    h6: {
      variant: 'styles.p',
      fontWeight: 'bold',
    },
    hr: {
      border: 'none',
      bg: 'gray.4',
      height: '1px',
      width: '54vh',
      my: 0,
      mx: 'pageMargin',
    },
    a: {
      ..._buttonStyles,
      bg: 'secondary',
      color: 'background',
      borderRadius: 4,
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    // pre: {
    //   fontFamily: 'monospace',
    //   overflowX: 'auto',
    //   code: {
    //     color: 'inherit',
    //   },
    // },
    // code: {
    //   fontFamily: 'monospace',
    //   fontSize: 'inherit',
    //   color: 'secondary',
    // },
    table: {
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: 0,
    },
    th: {
      textAlign: 'left',
      borderBottomStyle: 'solid',
    },
    td: {
      textAlign: 'left',
      borderBottomStyle: 'solid',
    },

  };

  const _ctaButtonStyles = {
    ..._buttonStyles,
    // flex: '1 1 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    width: 'auto', // SAFARI didn't eat 'max-content' value
    gap: 3,

    '.word': {
      variant: 'flex.left',
      display: 'flex !important',
      width: 'max-content',
    },

    '&, *': {
      fontSize: 'xl',
      fontWeight: 'medium',
    },

    'svg, img': {
      width: 'auto',
      height: 'auto',
      flex: '0 0 auto',
    },
  };

  const buttons = {
    // default style used by Button, pure style
    primary: {
      ..._buttonStyles,
      variant: 'flex.colCenter',
      flex: '0 0 auto',
    },
    // no styling sohuld be applied really for this kind
    // pure: {
    //   variant: 'text.default',
    //   background: 'none',
    //   outline: 'none',
    //   m: 0,
    //   p: 0,
    //   borderRadius: 0,
    //   minWidth: 'unset',
    //   minHeight: 'unset',
    //   textAlign: 'inherit',
    //   '&:hover': {
    //     boxShadow: 'none',
    //     transform: 'none !important',
    //   },
    //   cursor: 'pointer',
    // },
    cta: {
      ..._ctaButtonStyles,
      borderRadius: '1.25rem',
      bg: 'primary',

      px: '2.5625rem',
      py: '0.75rem',

      '&:hover': {
        transform: 'scale(1.03) !important',
      },
      '&, a, button': {
        variant: 'flex.left',
        flex: '0 0 auto',
        textDecoration: 'none !important',
        color: 'background',
        outline: 'none',
        gap: 3,
      },
    },
    smallCta: {
      variant: 'buttons.cta',
      '&, *': {
        color: '#3A7D44 !important',
        bg: 'transparent',
        fontSize: 'm',
        // color: 'background ',
      },

      py: 3,
      px: 4,
    },
    big: {
      variant: 'buttons.cta',
      ..._ctaButtonStyles,

      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: 'text',
      borderRadius: 6,
      bg: 'gray.3',

      minWidth: '515px',
      minHeight: '125px',
      pl: 5,
      pr: 5,
      pb: 3,
      pt: 3,
      m: 0,
      mr: 9,

      '&, a, button': {
        textDecoration: 'none',
        color: 'text',
      },
      '&:active, & a:active': {
        bg: 'gray.4',
      },

    },
  };

  const borders = {
    none: 'none',
    thin: '1px solid',
    thick: '3px solid',
    double: '3px double',
    debug: isDev ? 'dotted 1px cyan' :'initial',
  };

  const breakpoints = [
    // mobile before, too narrow portrait
    `${bodySizes.height*idealProportion*0.85}px`,
    // tablet between, ideal proportion
    `${bodySizes.height*idealProportion*1.14}px`,
    // desktop between, landscape
    `${bodySizes.height*idealProportion*1.2}px`,
    // landscape & widescreen
  ];

  const _colLeft = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flex: '1 1 auto',
  };

  const flex = {
    grow: {
      flex: '1 0 auto',
    },
    shrink: {
      flex: '0 1 auto',
    },
    center: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    between: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    left: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      flex: '1 1 auto',
    },
    right: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
      flex: '1 1 auto',
    },
    colCenter: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      flex: '1 1 auto',
    },
    colLeft: _colLeft,
    col: _colLeft,
    colRight: {
      ..._colLeft,
      alignItems: 'flex-end',
    },
    rowCenter: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      flex: '1 1 auto',
    },

    bottomButtons: {
      height: '6vh',
      variant: 'flex.between',
      px: 6,
      pb: 4,
      width: 'fullSize',
      border: 'debug',
      'button, a': {
        width: 'auto !important',
      },
      // variant: 'flex.between',
      // justifyContent: 'flex-start',
      // height: '13vh',
    },
  };

  const grids = {
    default: {
      border: 'thin',
      borderColor: 'primary',
    },
    cta: {
      position: 'absolute',
      height: 'auto',
      width: '100%',
      left: 0,
      gridGap: 0,
      bottom: '7vh',
      placeContent: 'center',
    },
    buttonWithCloud: {
      // borderRadius: '100%',
      // width: '200vh',
      // height: '160vh',
      // position: 'fixed',
      // bottom: '-138vh',
      // zIndex: 'popup',
      // border: 'debug',
    // backgroundImage: 'linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
    },
    indent: {
      display: 'grid',
      gridTemplateColumns: 'auto auto',
      gridGap: 6,
      placeContent: 'flex-start',
    },
  };

  const lists = {
    basicListType: {
      li: {
        mt: 0,
        mb: 4,
        textDecoration: 'none',
      },
      'li:last-of-type': {
        mb: 5,
      },
    },
    orderedListType: {
      variant: 'lists.basicListType',
      li: {
        listStylePosition: 'inside',
      },
    },
  };

  // used for Card component
  const cards = {
    chat: {
      textAlign: 'left',
      borderRadius: 6,
      borderTopLeftRadius: 0,
      py: '20px',
      px: 6,
      '&, p': {
        fontSize: 'm',
        color: 'primary',
        fontWeight: 'medium',
        letterSpacing: '0.01rem',
      },
      bg: 'background',
      width: '100%',
      height: 'auto',
      mb: 5,
      '&:last-child': {
        mb: 0,
      },
    },
  };

  const forms = {
    input: {
      bg: 'green1Light.2',
      height: '6.7vh',
      px: 6,
      outline: 'none',
      border: 'none',
      borderRadius: 4,
    },
    radio: {
      path: {
        fill: 'primary',
      },
    },
  };

  const links = {
    undercover: {
      textDecoration: 'none',
      color: 'inherit',
    },
  };

  const images = {
    icon: {
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: 'icon',
      width: 'icon',
      height: 'icon',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      mr: 4,
      'svg, img': {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
      },
    },
    fullWidth: {
      variant: 'images.icon',
      flex: '1 1 auto',
      width: 'fullSize',
      height: 'auto',
      'svg, img': {
        width: 'fullSize',
        objectFit: 'cover',
        height: 'fullSize',
      },
    },
  };

  return ({
    breakpoints,
    fonts,
    fontSizes,
    fontWeights,
    lineHeights,

    // `styles` primarily for MDX, in React have to use Styled.h1, Styled.p etc.
    // NOTE: this seems like an alternative to variants and sx: define default styles for certain tags, and use those, instead of creating variants for Text, Heading etc.
    styles,
    // text variants are the same
    text: styles,

    colors,
    borders,
    zIndices,
    buttons,
    flex,

    grids,
    lists,
    cards,
    forms,
    links,
    images,
    // let all spacing values also available for width/height as well as for margin/padding or border-radius
    sizes,
    space: sizes,
    radii: sizes,
    borderWidths: sizes,
  });
}

import {
  Box, Button, Flex, Themed,
} from 'theme-ui';
import { useHistory, useLocation } from 'react-router-dom';
import { steps } from '../config';

import { CTAButton } from '../components/core/themed';
import { Link } from '../components/core/router/Link';

import __ from '../hooks/useI18n';

const styles = {
  container: {
    minHeight: '94vh',
    justifyContent: 'flex-start',
    backgroundImage: 'linear-gradient(180deg, #CFE3FF 0%, #DAE8FA 83%, rgba(0,0,0,0) 83%, rgba(0,0,0,0) 100%)',
    '& > svg, img': {
      width: 'auto',
      height: '8.5vh',
    },
  },
  buttons: {
    position: 'fixed',
    bottom: '0vh',
    pt: '3vh',
    pb: '8vh',
    overflow: 'visible',
    variant: 'flex.colCenter',
    gap: '1.5vh',
    // background: 'linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 85%, rgba(255,255,255,1) 85%, rgba(255,255,255,1) 100%)',
  },
  disableButton: {
    '&, *': { fontSize: '1.5625rem', fontWeight: '600' },
  },
};

const indexing = [
  null,
  'work',
  'health',
  'people',
  'future',
  null,
  'family',
  'residence',
  'more',
];

export const ProductButtons = ({ match, className, sx }) => {
  const { go } = useHistory();
  const { pathname } = useLocation();
  const root = `${pathname}`.match((/^\/([^/]+)/));

  const negateParam = (param, index) => {
    if (param === null) return null;
    return ((index === +match.params.id) ? (!(+match.params[param]) * 1) : match.params[param]);
  };

  const disableLink = `${root[0]}/${indexing.map(negateParam).filter(param => param !== null).join('/')}`;

  return (
    <Box sx={{ ...styles.buttons, ...sx }} className={`button-container ${className}`}>
      <CTAButton onClick={() => go(-1)}>
        {__('Zurück zur Übersicht')}
      </CTAButton>
      <Button variant="smallCta" sx={styles.disableButton}>
        <Link to={disableLink}>
          {__('Diese Absicherung brauche ich nicht')}
        </Link>
      </Button>
    </Box>
  );
};

export const ProductDetails = ({ match, children, sx }) => {
  const Icon = (match.params && match.params.id && steps.protection.options[match.params.id].icon);

  return (
    <Flex variant="flex.colCenter" sx={{ ...styles.container, ...sx }}>
      {Icon && <Icon />}

      <Themed.h4 sx={styles.title}>{__('Absicherung Deiner Arbeitskraft')}<br /> – {__('Die Berufsunfähigkeitsversicherung')}</Themed.h4>

      {children}
    </Flex>
  );
};

/* eslint-disable object-property-newline */
import gsap from 'gsap';
import {
  createContext, useContext, useEffect, useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { slideDelay } from '../../config';
import { ReactComponent as AngelBackgroundIcon } from './bubble.svg';

const AnimationAngelBackgroundContext = createContext();

export function AnimationAngelBackgroundProvider({ children }) {
  const [animation, setAnimation] = useState(gsap.to({}, {}));
  const [status, setStatus] = useState('init');

  return (
    <AnimationAngelBackgroundContext.Provider value={[animation, setAnimation, status, setStatus]}>
      {children}
    </AnimationAngelBackgroundContext.Provider>
  );
}

export const useAnimationAngelBackgroundContext = () => useContext(AnimationAngelBackgroundContext);

export function useAnimationAngelBackgroundIntro(stepLength, start = false) {
  const [animation, setAnimation, , setStatus] = useAnimationAngelBackgroundContext();

  const duration = (stepLength)*slideDelay;

  useEffect(() => {
    const settings = {
      ease: 'linear',
      // width: 'auto',
      opacity: 1,
      height: '31vh',
      scale: 1,
    };
    const bubblePositions = [
      /* eslint-disable object-curly-newline */
      // { x: '140vh', y: '-20vh', ...settings },
      { x: '65vh', y: '-20vh', ...settings },
      // { x: '45vh', y: '-8vh', scale: 1, ...settings },
      // { x: '40vh', y: '-10vh', scale: 1, ...settings },
      { x: '-55vh', y: '-16vh', ...settings },
      // { x: '-17vh', y: '-16vh', scale: 1, ...settings },
      // { x: '-17vh', y: '-26vh', scale: 1.5, ...settings },
      /* eslint-enable object-curly-newline */
    ];

    setAnimation(
      gsap.timeline({
        paused: !start,
        onStart: () => setStatus('start'),
        onComplete: () => setStatus('complete'),
      })
        .fromTo('svg#angel-background', { ...bubblePositions[0] }, { ...bubblePositions[1] })
        // .to('svg#angel-background', { ...bubblePositions[2] })
        // .to('svg#angel-background', { ...bubblePositions[3] })
        // .to('svg#angel-background', { ...bubblePositions[4] })
        // .to('svg#angel-background', { ...bubblePositions[5] })
        .duration(duration),
      // .pause(0)
    );

    // {
    //   ...bubblePositions[bubblePositions.length-1],
    // // { ...bubblePositions[parseInt(step, 10)||1]
    // }).pause()
  }, []);

  return ({ bubble: animation, duration });
}

export const AngelBackground = () => {
  const location = useLocation();
  const [animation,, status] = useAnimationAngelBackgroundContext();

  useEffect(() => {
    const angelBGStyles = ((loc) => {
      const baseStyles = {
        top: '16vh', width: '14vh', height: '10vh',
      };
      const root = `${loc}`.match((/^\/([^/]+)/));
      switch (root && root[0]) {
      case '/intro': // intro governed by useAnimationAngelBackgroundIntro
        return null;
      case null: // landing
        return {
          content: '"intro"',
          x: '63vh', y: '-20vh', opacity: 0,
        };
      case '/plans':
        return {
          content: '"plans"',
          ...baseStyles,
          x: 0, y: 0, top: '16vh', width: '14vh', height: '10vh', opacity: 0, scale: 0.4, duration: 1,
        };
      case '/protection':
      case '/loading':
        // DIRTY HACK: Workaround needed for SAFARI
        gsap.set('svg#angel-background', { fill: 'white' });
        gsap.set('svg#angel-background path', { fill: 'white' });
        return {
          content: '"protection&landing"',
          ...baseStyles,
          x: 0, y: 0, top: '14.5vh', width: '14vh', height: '10vh', opacity: 1, scale: 1,
        };
      case '/final':
        return {
          content: '"final"',
          width: '30vh',
          height: '30vh',
          opacity: 0,
          left: '-40vh',
          zIndex: 0,
        };
      default: // status, crystal
        animation.progress(1);
        animation.pause(1);
        return {
          content: '"default"',
          ...baseStyles,
          x: 0, y: 0, top: '14.5vh', width: '14vh', height: '10vh', opacity: 1, scale: 1,
        };
      }
    })(location.pathname);
    // console.log('angelbg styles', `${location.pathname}`.match((/^\/([^/]+)/)), angelBGStyles);

    if (angelBGStyles) gsap.to('svg#angel-background', angelBGStyles);
  }, [location, status]);

  return <AngelBackgroundIcon id="angel-background" />;
};

import { Button } from 'theme-ui';
import { Link } from './Link';

export const ComingSoon = ({ children }) => (
  <Button variant="smallCta" sx={{ px: 0, fontWeight: '600' }}>
    <Link to="?coming-soon">
      {children}
    </Link>
  </Button>
);

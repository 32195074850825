import { Box, ThemeProvider } from 'theme-ui';
import React from 'react';
// import ClipLoader from 'react-spinners/ClipLoader';
import gsap from 'gsap';
import GSDevTools from 'gsap/GSDevTools';
import { useIsAuthenticated } from 'react-auth-kit';

import { Angel } from '../angel/Angel';
import { Clouds } from './Cloud';
import { Page } from './Page';
import { ProgressNav } from './ProgressNav';
import { getTheme } from '../../config';

import tabletBg from '../../static/images/tablet.png';
import { OptionClickProvider } from './Options';
import { DevTools } from './DevTools';
import { AnimationAngelBackgroundProvider } from '../angel/AngelBackground';
import { Login, VersionInfo } from './Login';
// import { hiddenScrollBars } from '../../config/theme-ui';

export const styles = {
  tablet: {
    backgroundImage: `url(${tabletBg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'auto 100vh',
    backgroundPosition: 'center center',
    // ...hiddenScrollBars,
  },
  // overlay: {
  //   position: 'absolute',
  //   bg: 'rgba(2,25,83, 0.6)',
  //   zIndex: 'popup',
  //   width: '100%',
  //   height: '100%',
  //   variant: 'flex.center',
  // },
};

let layoutTimeout;

function getDocumentSize() {
  const bodySizes = document.body.getBoundingClientRect();
  return {
    width: bodySizes.width < window.innerWidth ? bodySizes.width: window.innerWidth,
    height: bodySizes.height < window.innerHeight ? bodySizes.height: window.innerHeight,
  };
}

export const Layout = ({ overLays, children }) => {
  const isAuthenticated = useIsAuthenticated();

  const [dimensions, setDimensions] = React.useState(getDocumentSize());
  // const [isReloading, setReloading] = React.useState(false);
  const theme = getTheme(dimensions);

  gsap.registerPlugin(GSDevTools);

  window.addEventListener('resize', async () => {
    clearTimeout(layoutTimeout);
    layoutTimeout = setTimeout(() => {
      setDimensions(getDocumentSize());
    }, 200);

    // const pxTolerance = 40; // this minght needed due that the browsers are hiding the url bar on scroll
    // if ((Math.abs(dimensions.width - document.body.getBoundingClientRect().width) > pxTolerance
    // || Math.abs(dimensions.height - document.body.getBoundingClientRect().height) > pxTolerance)) {
    //   setReloading(true);
    //   // eslint-disable-next-line no-alert
    //   setTimeout(() => window.location.replace(window.location.href), 100);
    // }
  });

  return (
    <OptionClickProvider>
      <AnimationAngelBackgroundProvider>
        <ThemeProvider theme={theme}>
          {/* {isReloading && (
          <Box sx={styles.overlay}>
            <ClipLoader color={theme.colors.primary} loading={isReloading} size={150} />
          </Box>
        )} */}
          {/* TODO: refactor content to other file */}
          {!isAuthenticated() && <Login><VersionInfo /></Login>}

          {isAuthenticated() && (
            <Box sx={styles.tablet}>
              <DevTools />
              <Box as="main" variant="styles.main">
                <ProgressNav />
                <Angel />
                {overLays}
                <Page>
                  {children}
                </Page>
                <Clouds />
              </Box>
            </Box>
          )}
        </ThemeProvider>
      </AnimationAngelBackgroundProvider>
    </OptionClickProvider>
  );
};

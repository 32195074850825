import gsap from 'gsap';
import { useLayoutEffect, useRef } from 'react';
import { Box, Card, Text } from 'theme-ui';
import { useLocation } from 'react-router-dom';
import { chatDelay } from '../../config';

const styles = {
  bubble: {
    // fontSize: ['0.815rem !important', 'm', 'm'],
    m: 0,
  },
};

export const Bubble = ({
  children, sx, start, index,
}) => {
  const containerRef = useRef(null);
  const location = useLocation();

  const bubbleId = `bubble-${location.pathname.replace(/\W+/gi, '')}-${index}`;

  useLayoutEffect(() => {
    const loadAnim = gsap.timeline()
      .set(`#${bubbleId}`, {
        width: '120px',
        height: '70px',
      })
      .set(`#${bubbleId} .spinner`, {
        position: 'absolute',
        opacity: 1,
        x: 0,
      })
      .set(`#${bubbleId} .text`, {
        position: 'absolute',
        opacity: 0,
        x: '-20px',
      })
      .to(`#${bubbleId} .spinner svg circle`, {
        opacity: 0.3,
        stagger: 0.2,
        duration: 0.4,
        repeat: 2,
      })
      .to(`#${bubbleId} .spinner svg circle`, {
        opacity: 1,
        stagger: 0.2,
        duration: 0.4,
        repeat: 2,
      }, '<+0.5')
      .to(`#${bubbleId} .spinner`, {
        opacity: 0,
        x: '-20px',
        duration: 0.5,
      })
      .to(`#${bubbleId}`, {
        width: '100%',
        height: 'auto',
        duration: 0.3,
      })
      .to(`#${bubbleId} .text`, {
        x: 0,
        position: 'relative',
        opacity: 1,
        duration: 2.2,
      })
      .duration(chatDelay*0.8)
      .pause(0);
    // console.log('cont', containerRef.current.id, start);
    if (start) {
      loadAnim.restart();
    }
  }, [containerRef, start]);

  return (
    <Card variant="chat" id={bubbleId} className="bubble" sx={sx} ref={containerRef}>
      <Box className="spinner">
        <svg width="63" height="15" viewBox="0 0 63 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="7.5" cy="7.5" r="7.5" fill="#CFE3FF" />
          <circle cx="31.5" cy="7.5" r="7.5" fill="#CFE3FF" />
          <circle cx="55.5" cy="7.5" r="7.5" fill="#CFE3FF" />
        </svg>
      </Box>
      <Text className="text" as="p" sx={styles.bubble}>
        {children}
      </Text>
    </Card>
  );
};

import { Box } from 'theme-ui';

import { BackButton } from './router/BackButton';

const styles = {
  buttons: {
    variant: 'flex.bottomButtons',
  },
};

export const NavigationBottom = ({ children, sx, ...props }) => (
  <Box {...props} sx={{ ...styles.buttons, ...sx }}>
    <BackButton />
    {children}
  </Box>
);

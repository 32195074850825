import { Box } from 'theme-ui';
import { useLayoutEffect } from 'react';
import gsap from 'gsap/gsap-core';
import { useLocation } from 'react-router-dom';
import { OptionItem, useOptionClickContext } from './layout/Options';
import { steps } from '../config';
// import iconPosition from '../static/images/status/options/position.svg';
import { useCurrentStepName } from '../hooks/useCurrentStepName';
import { usePrevStepName } from '../hooks/usePrevStepName';
import { selectionDelimiters } from '../hooks/usePropertiesLink';

const setIconHeight = (height) => ({
  '.icon': {
    width: 'auto',
    height,
    'img, svg': {
      height: 'fullSize',
    },
  },
});

export const styles = {
  propertyContainer: {
    border: 'debug',
    position: 'absolute',
    overflow: 'hidden',
    top: '33vh',
    width: '100%',
    height: '50vh',
    zIndex: 'properties',
  },
  propertyPosition: {
    position: 'absolute',
  },
};

const Property = (props) => (<OptionItem {...props} id={undefined} />);

// TODO: random location for multiple
const OneOrManyPropertyOption = ({ options, value: oneOrManyValue }) => (
  <>
    {oneOrManyValue.split(selectionDelimiters.multiple).map((value, index) => options[value] && <Property key={index} {...options[value]} />)}
  </>
);

export const PropertyContainer = ({ children, match, delay }) => {
  const currentStepName = useCurrentStepName(match);
  const prevStepName = usePrevStepName(match);
  const { pathname } = useLocation();

  const positions = {
    /* eslint-disable object-curly-newline */
    birthdate: { left: '15vh', top: '12vh', zIndex: 11, gridArea: 'birthdate' },
    profession: { left: '20vh', top: '34vh', zIndex: 2, gridArea: 'profession',
    // , display: `${currentStepName}`.match(/(profession|income|family)/) ? 'block': 'none'
    },
    income: { left: '-9vh', top: '34vh', zIndex: 3, gridArea: 'income', ...setIconHeight('6vh') },
    family: { left: '6.5vh', top: '22vh', zIndex: 10, gridArea: 'family', ...setIconHeight('21vh') },
    residence: { left: '-2vh', top: '8vh', zIndex: 5, transform: 'scale(1.5)', gridArea: 'residence', ...setIconHeight('17vh') },
    property: { left: '-8vh', top: '23vh', zIndex: 6, gridArea: 'property', ...setIconHeight('9vh') },
    belongings: {
      left: '-22vh',
      top: '15vh',
      zIndex: 7,
      gridArea: 'belongings',
      position: 'relative',
      '.icon': { position: 'absolute', height: 'auto', alignItems: 'flex-start' },
      '.icon#Cat': { left: '72vh', top: '16vh' },
      // '.icon#Cat': { left: '67vh', top: '-11vh', img: { transform: 'rotate(30deg) scaleX(-1)' } }, // rooftop
      '.icon#Hund': { left: '83vh', top: '19vh' },
      '.icon#Bicycle': { left: '37vh', top: '14.5vh', width: '9.5vh', height: 'auto', img: { width: '100%' } },
      '.icon#Motor': { left: '25vh', top: '15vh', width: '10vh', height: 'auto', img: { width: '100%' } },
      '.icon#Car': { left: '27vh', top: '7vh', width: '14vh', height: 'auto' },
      '.icon#Pferd': { left: '26vh', top: '2vh' },
      '.icon#Drohne': { left: '40vh', top: '-8vh' },
    },
    /* eslint-enable   object-curly-newline */
  };

  const [lastOptionClicked] = useOptionClickContext();

  useLayoutEffect(() => {
    const selectorRoot = `#property #status-${prevStepName} .item:last-child`;
    const selector = `${selectorRoot} .icon`;
    const isLastIdentified = lastOptionClicked && document.querySelector(selector);
    const x = isLastIdentified && lastOptionClicked.x- document.querySelector(selector).getBoundingClientRect().x;
    const y = isLastIdentified && lastOptionClicked.y- document.querySelector(selector).getBoundingClientRect().y;
    gsap.timeline()
      .from(`${selector}`, {
        y: y || '50vh',
        ease: 'back',
      })
      .from(`${selector}`, {
        x: x || 0,
        ease: 'power.in',
      }, '<');
  }, [prevStepName, pathname]);

  // useLayoutEffect(() => {
  //   if (positions[currentStepName]) {
  //     gsap.to('#placeholder', {
  //       scale: 1.05,
  //       repeat: -1,
  //       yoyo: true,
  //     });
  //   }
  // }, [currentStepName, pathname]);

  return (
    <Box sx={styles.propertyContainer} id="property">
      {Object.entries(positions).map(([stepName]) => {
        const oneOrManyselection = match.params[stepName];
        const PropertyComponent = stepName && oneOrManyselection && steps.status[stepName] && steps.status[stepName].component;

        return (
          <Box sx={{ ...styles.propertyPosition, ...positions[stepName] }} key={stepName} id={`status-${stepName}`}>
            {stepName && oneOrManyselection && steps.status[stepName] && steps.status[stepName].options && (
              <OneOrManyPropertyOption {...steps.status[stepName]} value={oneOrManyselection} />
            )}
            {PropertyComponent && (
              <PropertyComponent value={oneOrManyselection} component={OptionItem} id={undefined} />
            )}
          </Box>
        );
      })}

      {children}

      {currentStepName && positions[currentStepName] && (
        <Box sx={{
          ...styles.propertyPosition, ...positions[currentStepName], transform: 'none', transition: 'all 0.7s ease', transitionDelay: `${delay+0.7}s`,
        }}
        >
          {steps.status[currentStepName].options && steps.status[currentStepName].config && steps.status[currentStepName].config.multiple && match.params[currentStepName] && (
            <>
              <OneOrManyPropertyOption {...steps.status[currentStepName]} value={match.params[currentStepName]} />
            </>
          )}
          {/* <img src={iconPosition} alt="placeholder" id="placeholder" /> */}
        </Box>
      )}

    </Box>
  );
};

// import { Themed } from 'theme-ui';
import { OpenCollapse } from '../components/core/OpenCollapse';
import { ComingSoon } from '../components/core/router/ComingSoon';

import { Paragraph, Title } from '../components/core/themed';
import { ProductDetails } from './ProductDetails';
import iconEdit from '../static/images/final/icon.edit.png';
import { CurrencyInput } from '../components/core/CurrencyInput';

import __ from '../hooks/useI18n';

export const styles = {
  container: {
    backgroundImage: 'linear-gradient(249deg, #E0EDFF 0%, #F2F7FD 100%)',
    pb: '22vh', // button container height

    // '.button-container': {
    //   // TODO: move into theme-ui
    //   backgroundImage: `url(${cloudButton})`,
    //   backgroundSize: '100% 100%',
    //   backgroundPosition: 'bottom center',
    //   backgroundRepeat: 'no-repeat',
    //   '& > *': {
    //     zIndex: 'float',
    //   },
    //   // '&:before': {
    //   //   content: '""',
    //   //   variant: 'grids.buttonWithCloud',
    //   //   bottom: '-145vh',
    //   // },
    // },
  },
  title: {
    textAlign: 'right',
    width: 'fullSize',
    pt: 4,
    pb: 5,
  },
  input: {
    width: '45vh',
    backgroundImage: `url(${iconEdit})`,
    backgroundSize: 'auto 40%',
    backgroundPosition: '40vh center',
    backgroundRepeat: 'no-repeat',
  },
  subOpenCollapse: {
    pl: 6,
    width: '42.5vh',
    border: 'none',
  },
};

export const FinalDetails = (props) => (
  <ProductDetails {...props} sx={styles.container}>

    <Paragraph>
      {__('Auf Basis meiner Empfehlung ergibt sich für Dich eine voraussichtliche monatliche Zahlung in Höhe von 55 Euro. Dieser Beitrag wird von den folgenden drei Faktoren bestimmt. Du kannst diese auf Basis Deiner persönlichen Vorstellungen anpassen.')}
    </Paragraph>

    <Paragraph as="div">

      <ComingSoon>{__('Mehr über die Absicherung erfahren')}</ComingSoon>

      <Title sx={styles.title}>
        {__('55 € p. Monat')}
      </Title>

      <OpenCollapse header={__('Monatliche Auszahlung')} info={__('2000€')}>
        <CurrencyInput sx={styles.input} value={2000} />

        <OpenCollapse header={__('Welchen Beitrag Du wählen solltest')} sx={styles.subOpenCollapse}>
          {__('Wie hoch soll die monatliche Zahlung sein? Als Faustregel gilt 60% - 80% von Deinem heutigen Nettoeinkommen.')}
        </OpenCollapse>

        <OpenCollapse header={__('Was Du außerdem beachten solltest')} sx={styles.subOpenCollapse} />
      </OpenCollapse>

      <OpenCollapse header={__('Ende Deiner Absicherung')} info={__('67 Jahre')} />
      <OpenCollapse header={__('Jährliche Erhöhung')} info="3 %" />

      <ComingSoon>{__('Produktunterlagen')}</ComingSoon>
      <ComingSoon>{__('Kündigungsschreiben für bestehenden Versicherer')}</ComingSoon>
      <ComingSoon>{__('Vermittlerinformationen (Art. 45 VAG)')}</ComingSoon>

    </Paragraph>

  </ProductDetails>
);

import { Box, Flex, useThemeUI } from 'theme-ui';
import DotLoader from 'react-spinners/DotLoader';
import { useLayoutEffect, useRef } from 'react';
import gsap from 'gsap/gsap-core';
import { Bubbles } from '../components/layout/Bubbles';
import { steps, loadingDelay } from '../config';

import { PropertyContainer } from '../components/PropertyContainer';
import { CrystalBubble } from '../components/custom/CrystalBubble';
import { useGoTo } from '../components/core/router/Link';

export const styles = {
  options: {
    position: 'absolute',
    width: '100%',
    bottom: '10vh',
    variant: 'flex.center',
    zIndex: 'options',
  },
  crystal: {
    position: 'absolute',
    zIndex: '3',
    width: '9vh',
    height: '9vh',
    left: '14vh',
    top: '38vh',
    svg: {
      width: '100%',
      height: '100%',
    },
  },
};

export const Loading = ({ match }) => {
  const spinnerRef = useRef(null);
  const goTo = useGoTo();
  const { theme } = useThemeUI();

  useLayoutEffect(() => {
    gsap.timeline()
      .from('#property', {
        y: '70vh',
        opacity: 1,
        ease: 'circ',
        duration: 1.2,
      })
      .from(spinnerRef.current, {
        opacity: 0,
        ease: 'back',
      }, 1);

    setTimeout(() => goTo('/protection/1/1/1/1/0/0/0'), loadingDelay*1000);
  }, [spinnerRef]);

  return (
    <Flex variant="flex.colCenter">
      <Bubbles texts={steps.loading.bubbles} sx={styles.bubbles} />

      <PropertyContainer match={match}>
        <CrystalBubble sx={styles.crystal} />
      </PropertyContainer>

      <Box sx={styles.options} ref={spinnerRef}>
        <DotLoader color={theme.colors.primary} loading size={150} />
      </Box>

    </Flex>
  );
};

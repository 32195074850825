import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Box,
  useThemeUI,
} from 'theme-ui';

const styles = {
  container: (active) => ({
    opacity: active ? 1 : 0,
    position: 'fixed',
    zIndex: 'float',
    right: active ? 7 : -7,
    top: '33.6vh',
    width: 'auto',
    height: theme => theme.sizes.getScrollBarHeight(),
  }),
  line: {
    variant: 'flex.left',
    flexDirection: 'column',
    width: 3,
    height: '100%',
    bg: 'primaryLight.3',
    overflow: 'visible',
  },
  thumb: {
    borderRadius: 'fullSize',
    overflow: 'visible',
    bg: 'green1',
    width: 6,
    height: 6,
  },
};

export const ScrollBar = ({
  scrollerRef, contentRef, onScroll, ...props
}) => {
  const { pathname } = useLocation();
  const [animation, setAnimation] = useState(null);
  const [scrollerHeight, setScrollerHeight] = useState(0);
  const [contentHeight, setContentHeight] = useState(0);
  const thumbRef = useRef(null);
  gsap.registerPlugin(ScrollTrigger);
  const { theme } = useThemeUI();
  // ScrollTrigger.saveStyles(); // do we need responsive support?

  useEffect(() => {
    const updateHeight = (height, obj, setter) => {
      if (obj) {
        const currentHeight = Math.round(obj.getBoundingClientRect().height);
        const heightDiff = Math.abs(1-(height/currentHeight)*100);
        if (heightDiff < 98) {
          setter(currentHeight);
          // console.log('animation.refresh!', height);
          if (animation) animation.refresh();
        }
      }
    };
    const refreshAnimation = () => {
      updateHeight(contentHeight, contentRef.current, setContentHeight);
      updateHeight(scrollerHeight, scrollerRef.current, setScrollerHeight);
    };

    const interval = setInterval(refreshAnimation, 100);

    return () => {
      clearInterval(interval);
    };
  }, [contentRef, contentHeight, animation]);

  useEffect(() => {
    if (contentRef && thumbRef) {
      // const bottom = parseInt(theme.sizes.getRelativeHeight(0.1), 10);
      // console.log('theme.sizes.getRelativeHeight()', bottom, contentHeight);
      setAnimation(
        ScrollTrigger.create({
          markers: process.env.NODE_ENV === 'development',
          scroller: scrollerRef.current || document.body,
          trigger: contentRef.current,
          start: () => 'top top', // when the top of the trigger hits the top of the viewport
          end: () => 'bottom bottom', // add safety 150px which shall be needed only on resized
          scrub: 0.2,
          // onEnter: () => console.log('onEnter'),
          // onLeave: () => console.log('onLeave'),
          // onEnterBack: () => console.log('onEnterBack'),
          // onLeaveBack: () => console.log('onLeaveBack'),
          onUpdate: () => onScroll && onScroll(animation),
          animation: gsap.timeline().to(thumbRef.current, {
            y: theme.sizes.getScrollBarHeight(),
          }),
        }),
      );
    }
    return () => {
      if (animation)animation.kill();
    };
  }, [scrollerRef, contentHeight, contentRef, thumbRef]);

  // (protection(\/[\w-,]+){8})|
  return (
    <Box {...props} sx={styles.container(contentHeight > scrollerHeight && pathname.match(/^\/((final.*))$/))}>
      <Box sx={styles.line}>
        <Box sx={styles.thumb} ref={thumbRef} />
      </Box>
    </Box>
  );
};

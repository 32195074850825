import { Box, Flex } from 'theme-ui';
import { Bubbles } from '../components/layout/Bubbles';
import { steps } from '../config';

import { PropertyContainer } from '../components/PropertyContainer';
import { CrystalBubble } from '../components/custom/CrystalBubble';
import { usePropertiesLink } from '../hooks/usePropertiesLink';

export const styles = {
  options: {
    position: 'absolute',
    width: '100%',
    bottom: 0,
    variant: 'flex.center',
    zIndex: 'options',
  },
};

export const Crystal = ({ match }) => {
  const link = usePropertiesLink(steps.crystal.link);

  return (
    <Flex variant="flex.colCenter">
      <Bubbles texts={steps.crystal.bubbles} sx={styles.bubbles} />

      <PropertyContainer match={match} />

      <Box sx={styles.options}>
        <CrystalBubble link={link} />
      </Box>

    </Flex>
  );
};

/* eslint-disable object-curly-newline */
import { Box } from 'theme-ui';
import { useLocation } from 'react-router-dom';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import { MorphSVGPlugin } from 'gsap/MorphSVGPlugin.js';
import { ReactComponent as FlyingFrontAngel } from './flying.front.svg';
import { ReactComponent as StandingAngel } from './standing.svg';
import { ReactComponent as WalkingAngel } from './walking.svg';
import { ReactComponent as FlyingSideAngel } from './flying.side.svg';
import { AngelBackground } from './AngelBackground';

gsap.registerPlugin(MorphSVGPlugin);

const styles = {
  container: (visible) => ({
    opacity: visible ? 1 : 0,
    height: '100%',
    position: 'absolute',
    svg: {
      overflow: 'visible',
      position: 'absolute',
      opacity: 0,
    },
  }),
};

const StandingAngelAnimation = ({ visible, land, fly }) => {
  const containerRef = useRef(null);
  const [flyDownAnim, setFlyDownAnim] = useState(null);
  const [flyingAnim, setFlyingAnim] = useState(null);
  const [hasPlayed, setPlayed] = useState(false);

  useLayoutEffect(() => {
    if (containerRef && containerRef.current && !flyDownAnim) {
      setFlyDownAnim(gsap.timeline({
        paused: true,
        onComplete: () => setPlayed(true),
      })
        .from(`#${containerRef.current.id} svg#flying-front`, { y: '-80vh', duration: 2, ease: 'circ' })
        .from(`#${containerRef.current.id} svg#flying-front #right-wing`, { rotate: '-30deg', scaleX: 1.2, ease: 'sine.in', duration: 0.5, transformOrigin: '90% 90%', repeat: 4, yoyo: 1 }, 0)
        .from(`#${containerRef.current.id} svg#flying-front #left-wing`, { rotate: '30deg', scaleX: 1.2, ease: 'sine.in', duration: 0.5, transformOrigin: '10% 90%', repeat: 4, yoyo: 1 }, 0)
        .to(`#${containerRef.current.id} svg#flying-front > g > path`, {
          morphSVG: (_, el) => `#${containerRef.current.id} svg#standing #${el && el.id}`,
          duration: 0.2,
        }, 2));
    }

    if (containerRef && containerRef.current && !flyingAnim) {
      setFlyingAnim(
        gsap.timeline({
          paused: true,
        })
          .from(`#${containerRef.current.id}`, { y: -10, ease: 'sine.in', duration: 0.5, repeat: -1, yoyo: 1 })
          .from(`#${containerRef.current.id} #flying-front2 #right-wing`, { rotate: '-30deg', scaleX: 1.2, ease: 'sine.in', duration: 0.5, transformOrigin: '90% 90%', repeat: -1, yoyo: 1 }, '<')
          .from(`#${containerRef.current.id} #flying-front2 #left-wing`, { rotate: '30deg', scaleX: 1.2, ease: 'sine.in', duration: 0.5, transformOrigin: '10% 90%', repeat: -1, yoyo: 1 }, '<'),
      );
    }
  }, [containerRef, land, fly]);

  useLayoutEffect(() => {
    let playFly = false;
    if (flyingAnim) {
      if (fly) {
        gsap.set(`#${containerRef.current.id} svg#flying-front2`, { opacity: 1 });
        gsap.set(`#${containerRef.current.id} svg#flying-front`, { opacity: 0 });
        flyingAnim.play(0);
        playFly = true;
      } else {
        flyingAnim.pause(0);
      }
    }

    if (flyDownAnim && !playFly) {
      gsap.set(`#${containerRef.current.id} svg#flying-front2`, { opacity: 0 });
      gsap.set(`#${containerRef.current.id} svg#flying-front`, { opacity: 1 });
      if (!hasPlayed) {
        if (land) {
          flyDownAnim.restart();
        } else {
          flyDownAnim.pause(0);
        }
      } else {
        flyDownAnim.pause(flyDownAnim.duration());
      }
    }
  }, [flyDownAnim, land, fly, hasPlayed]);

  return (
    <Box sx={styles.container(visible)} ref={containerRef} id="front">
      <StandingAngel id="standing" />
      <FlyingFrontAngel id="flying-front" />
      <FlyingFrontAngel id="flying-front2" />
    </Box>
  );
};

const WalkingAngelAnimation = ({ visible }) => {
  const containerRef = useRef(null);
  const location = useLocation();
  const [fylingAnim, setFlyingAnim] = useState(null);

  useLayoutEffect(() => {
    gsap.set(`#${containerRef.current.id} svg#walking`, { opacity: 1 });
    MorphSVGPlugin.convertToPath('circle, rect, ellipse, line, polygon, polyline');
    // eslint-disable-next-line no-unused-vars
    setFlyingAnim(
      gsap.timeline({
        paused: true,
      })
        .to(`#${containerRef.current.id} svg#walking > g#walking > path`, {
          morphSVG: (_, el) => `#${containerRef.current.id} svg#flying-side #${el && el.id}`,
          duration: 0.2,
        })
        .to(`#${containerRef.current.id} svg#walking`, {
          y: '-6vh',
          x: '4vh',
          rotate: 6,
          duration: 0.2,
        }, '<')
        .addLabel('fyling-start')
        .from(`#${containerRef.current.id} svg#walking #left-wing`, {
          repeat: 2,
          yoyo: 1,
          rotate: 20,
          scaleX: 1.2,
          transformOrigin: '50% 90%',
          duration: 0.3,
          ease: 'sine.in',
        }, '>')
        .from(`#${containerRef.current.id} svg#walking #right-wing`, {
          repeat: 2,
          yoyo: 1,
          rotate: -20,
          scaleX: 1.2,
          transformOrigin: '90% 90%',
          duration: 0.3,
          ease: 'sine.in',
        }, '<')
        .to(`#${containerRef.current.id} svg#walking`, {
          repeat: 2,
          yoyo: 1,
          y: -10,
          duration: 0.3,
          ease: 'sine.inOut',
        }, '<+=0.2')
        .addLabel('fyling-end')
        .to(`#${containerRef.current.id} svg#walking > g#walking > path`, {
          morphSVG: (_, el) => `#${containerRef && containerRef.current && containerRef.current.id} svg#walking #${el && el.id}`,
          duration: 0.2,
        })
        .to(`#${containerRef.current.id} svg#walking`, {
          y: 0,
          x: 0,
          rotate: 0,
          duration: 0.2,
        }, '<'),
    );
  }, [containerRef]);

  useEffect(() => {
    if (fylingAnim) {
      fylingAnim.restart(0);
    }
  }, [location.pathname]);

  return (
    <Box sx={{ ...styles.container(visible), mt: '-5vh' }} ref={containerRef} id="side">
      <WalkingAngel id="walking" />
      <FlyingSideAngel id="flying-side" />
    </Box>
  );
};

export const Angel = () => {
  const location = useLocation();
  // const history = useHistory();
  const containerRef = useRef(null);

  const isLanding = location.pathname.match(/^(\/|\/de|\/en|\/fr|)$/);

  const baseStyles = {
    variant: 'images.fullWidth',
    transition: 'all 0.2s ease-out',
    position: 'absolute',
    zIndex: 'angel',
    opacity: isLanding ? 0 : 1,
    m: 0,
    svg: {
      transition: 'all 0.2s ease-out',
      position: 'absolute',
    },
  };

  const angelStyles = ((loc) => {
    const root = `${loc}`.match((/^\/([^/]+)/));
    switch (root && root[0]) {
    case undefined:
    case null:
    case 'undefined':
    case '':
    case '/':
    case '/fr':
    case '/en':
    case '/de':
      return {
        ...baseStyles,
        top: '15vh',
        width: ['33vh', '67vh'],
        height: ['24vh', '48vh'],
      };
    case '/intro':
      return {
        ...baseStyles,
        top: '15vh',
        width: ['33vh', '67vh'],
        height: ['24vh', '48vh'],
      };
    case '/protection':
      return {
        ...baseStyles,
        opacity: loc.match(/detail/) || loc.match(/\/protection(\/[\w-,]+){8}/) ? 0 : 1,
        left: '1vh',
        top: '4vh',
        width: ['11vh', '24vh'],
        height: ['8vh', '22vh'],
        // '& #angel-background': {
        //   transform: 'scale(1) !important',
        //   height: '100% !important',
        // },
      };
    case '/final':
      return {
        ...baseStyles,
        opacity: loc.match(/detail/) || loc.match(/\/final(\/[\w-,]+){8}/) ? 0 : 1,
        left: '1vh',
        top: 'headerHeight',
        width: ['11vh', '24vh'],
        height: ['8vh', '22vh'],
      };
    default:
      return {
        ...baseStyles,
        left: '1vh',
        top: 'headerHeight',
        width: ['11vh', '24vh'],
        height: ['8vh', '22vh'],
        // '& #angel-background': {
        //   transform: 'scale(1) !important',
        //   height: '100% !important',
        // },
      };
    }
  })(location.pathname);

  const shallWalk = !!location.pathname.match(/^\/intro\/[1-2]+$/);

  return (
    <Box id="angel" sx={angelStyles} ref={containerRef}>
      <AngelBackground />

      <StandingAngelAnimation land={!isLanding && !shallWalk} fly={location.pathname.match(/^\/plans\/.+$/)} visible={!shallWalk} />
      <WalkingAngelAnimation visible={shallWalk} />
    </Box>
  );
};

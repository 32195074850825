import gsap from 'gsap';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box } from 'theme-ui';
import { Bubble } from './Bubble';
import { chatDelay } from '../../config';
import __ from '../../hooks/useI18n';

const styles = {
  container: (pathname) => ({
    pl: ['13vh', '27.5vh'],
    position: 'absolute',
    border: 'debug',
    height: 'auto',
    top: 0,
    pt: pathname.match(/^\/(protection|final)/) ? '4vh' : 'headerHeight',
    // flex: '1 1 29vh',
    pr: 7,
    zIndex: 'bubbles',
  }),
};

export const Bubbles = ({ sx, texts }) => {
  const containerRef = useRef(null);
  const { pathname } = useLocation();
  const [staggerIndex, setStaggerIndex] = useState(undefined);

  useEffect(() => {
    gsap.set('#bubbles > *', {
      y: -20,
    });
    gsap.to('#bubbles > *', {
      opacity: 1,
      y: (index) => {
        setStaggerIndex(index);
        return 0;
      },
      stagger: chatDelay,
      delay: 1,
    });
  }, [containerRef, texts]);

  return (
    <Box id="bubbles" sx={{ ...styles.container(pathname), ...sx }} ref={containerRef}>
      {texts && texts.map((text, index) => <Bubble key={text} sx={{ opacity: 0 }} index={index} start={staggerIndex === index}>{__(text)}</Bubble>)}
    </Box>
  );
};

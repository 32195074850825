import { useState } from 'react';
import {
  Box, Button, Paragraph,
} from './themed';
import { ReactComponent as IconDown } from '../../static/images/final/arrow.down.svg';

const getStyles = (open) => {
  const shrinkable = {
    transition: 'all 0.7s ease',
    overflow: 'hidden',
  };
  return ({
    container: {
      variant: 'flex.colCenter',
      borderBottom: 'thin',
      borderBottomColor: 'gray.3',
      minHeight: '6vh',
      mt: '0.7vh',
      pb: '1.1vh !important',
    },
    header: {
      position: 'relative',
      width: 'fullSize',
      pt: '1.1vh !important',
      fontWeight: open ? 'bold' : 'medium',
      '&, & p': {
        p: 0,
      },
      'svg, img': {
        ...shrinkable,
        position: 'absolute',
        right: 0,
        width: 'auto',
        height: '1vh',
        transform: open ? 'scaleY(-1)' : 'scaleY(1)',
      },
    },
    info: {
      ...shrinkable,
      p: 0,
      flex: !open ? '1 0 auto' : '0 1 0',
      height: !open ? 'auto' : '0',
      color: '#7C9CAC',
      width: '100%',
      textAlign: 'left',
    },
    content: {
      ...shrinkable,
      width: '100%',
      textAlign: 'left',
      transition: 'all 0.7s ease',
      flex: open ? '1 0 auto' : '0 1 0',
      height: open ? 'auto' : '0',
      pt: open ? 5 : 0,
    },
  });
};

export const OpenCollapse = ({
  header, info, children, open: openDefault= false, sx, ...props
}) => {
  const [open, setOpen] = useState(openDefault);
  const styles = getStyles(open);

  return (
    <Box {...props} sx={{ ...styles.container, ...sx }}>
      <Button sx={styles.header} onClick={() => setOpen(!open)}>
        <Paragraph sx={styles.header}>{header}</Paragraph>
        <IconDown />
      </Button>

      {info && <Paragraph sx={styles.info}>{info}</Paragraph>}

      <Box sx={styles.content}>
        {children}
      </Box>
    </Box>
  );
};

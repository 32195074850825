/* eslint-disable no-unused-vars */
import { gsap } from 'gsap';
import Draggable from 'gsap/Draggable';
import InertiaPlugin from 'gsap/InertiaPlugin';
import { useEffect, useRef, useState } from 'react';
import { Box, Button } from 'theme-ui';
import { slideDelay } from '../../config';

const styles = {
  container: {
    variant: 'flex.colCenter',
  },
  content: {
    variant: 'flex.left',
    alignItems: 'flex-start',
  },
  item: {
    border: 'debug',
    variant: 'flex.left',
    justifyContent: 'flex-start',
    width: 'fullSize',
    height: 'fullSize',
    flex: '0 0 100%',
  },
  dots: {
    variant: 'flex.center',
    mt: 5,
  },
  dot: ({ active }) => ({
    bg: active ? '#9DBDE9': '#CFE3FF',
    borderRadius: 'fullSize',
    width: 4,
    height: 4,
  }),
};

const CarouselItem = (props) => <Box {...props} sx={{ ...styles.item, ...props.sx }} />;

export const Carousel = ({
  id, items, activeIndex = 0, sx, onIndexChange, onMove,
}) => {
  const containerId = `carousel-${id}`;
  const contentSelector = `#${containerId} .carousel-content`;
  const numSlides = items.length;

  const containerRef = useRef(null);
  const [slideWidth, setSlideWidth] = useState(0);
  const containerWidth = slideWidth * numSlides;
  const snapX = gsap.utils.snap(slideWidth);

  gsap.registerPlugin(Draggable, InertiaPlugin);

  const calcItemOffsetX = (offsetX) => {
    const x = offsetX !== undefined ? offsetX : gsap.getProperty(contentSelector, 'x');
    if (x > 0) return 0;
    return Math.abs(x);
  };

  const calcTotalPercent = (offsetX) => (calcItemOffsetX(offsetX) / (containerWidth-slideWidth));

  const calcNewIndex = (offsetX) => Math.floor(numSlides * (calcItemOffsetX(offsetX) / (containerWidth)));

  useEffect(() => {
    function resize() {
      setSlideWidth(document.querySelector(`#${containerId} .carousel-item`).offsetWidth);
    }
    resize();
    window.addEventListener('resize', resize);

    return () => window.removeEventListener('resize', resize);
  }, [containerRef]);

  useEffect(() => {
    // on having opened the page with /intro/[#] directly, slide to the active slide
    gsap.to(contentSelector, {
      x: slideWidth*+activeIndex*-1,
    });

    onMove(calcTotalPercent(slideWidth*+activeIndex*-1));

    const getSnap = totaloffsetX => {
      const minX = (activeIndex < numSlides -1 ? +activeIndex+1 : numSlides -1) * -1*slideWidth;
      const snapped = snapX(totaloffsetX);
      const newX = (() => {
        if (snapped<minX) return minX;
        return snapped > 0 ? 0 : snapped;
      })();
      if (onIndexChange) onIndexChange(calcNewIndex(newX));
      if (onMove) onMove(calcTotalPercent(newX));
      return newX;
    };

    /* Dragging support */
    const draggable = new Draggable(contentSelector, {
      inertia: true,
      type: 'x',
      onThrowComplete: () => {
        if (onIndexChange) onIndexChange(calcNewIndex());
        if (onMove) onMove(calcTotalPercent());
      },
      // onThrowUpdate: () => onMove && onMove(calcTotalPercent()),
      onDrag: () => onMove && onMove(calcTotalPercent()), // during drag
      // onDragEnd: () => console.log('drag end'),
      onThrowUpdate: () => onMove && onMove(calcTotalPercent()), // during throw after drag
      snap: {
        x: getSnap,
      },
    });

    function autoPlay() {
      if (draggable.isPressed || draggable.isDragging || draggable.isThrowing) {
        // eslint-disable-next-line no-use-before-define
        timer.restart(true);
      } else if (activeIndex < numSlides -1)onIndexChange(+activeIndex + 1);
      // eslint-disable-next-line no-use-before-define
      else timer.kill();
    }

    const timer = gsap.delayedCall(slideDelay, autoPlay);

    return () => {
      timer.kill();
    };
  }, [containerRef, activeIndex, slideWidth]);
  /* // Dragging support */

  return (
    <Box id={`${containerId}`} ref={containerRef} sx={{ ...styles.container, ...sx }}>
      <Box className="carousel-content" sx={{ ...styles.content }}>
        {items.map((step, index) => (
          <CarouselItem key={`${containerId}-step${index}`} className="carousel-item" id={`${containerId}-step${index}`} {...step}>
            {step.children}
          </CarouselItem>
        ))}
      </Box>
      <Box sx={styles.dots}>
        {items.map((_, index) => (
          <Button key={`${containerId}-dot${index}`} onClick={() => onIndexChange(index)}>
            <Box className="carousel-dot" sx={styles.dot({ active: +index === +activeIndex })} id={`${containerId}-dot${index}`} />
          </Button>
        ))}
      </Box>
    </Box>
  );
};

import React from 'react';
import {
  Text, Heading,
} from 'theme-ui';

export { Text, Heading } from 'theme-ui';

export const SmallText = React.forwardRef(({ children, ...props }, ref) => (
  <Text variant="small" as="span" {...props} ref={ref}>
    {children}
  </Text>
));

// uses variant=text.heading by default
export const Title = React.forwardRef(({ children, sx, ...props }, ref) => (
  <Heading as="h1" sx={{ variant: 'styles.h1', ...sx }} {...props} ref={ref}>
    {children}
  </Heading>
));

// uses variant=text.heading by default
export const SubTitle = React.forwardRef(({ children, sx, ...props }, ref) => (
  <Heading as="h2" sx={{ variant: 'styles.h2', ...sx }} {...props} ref={ref}>
    {children}
  </Heading>
));

// NOTE: we should try to render as `div` by default, as due to preMarkdown we might have some elements (ie u, ol, hr) inside
export const Paragraph = React.forwardRef(({ children, ...props }, ref) => (
  <Text variant="styles.p" as="p" {...props} ref={ref}>
    {children}
  </Text>
));

import { Switch, Route } from 'react-router-dom';
import { Landing } from './pages/Landing';
import { Crystal } from './pages/Crystal';
import { Intro } from './pages/Intro';
import { Status } from './pages/Status';
import { Plans } from './pages/Plans';
import { Loading } from './pages/Loading';
import { Protection } from './pages/Protection';
import { Final } from './pages/Final';

export const urlParams = Object.freeze({
  status: '/:birthdate?/:profession?/:income?/:family?/:residence?/:property?/:belongings?/:morebelongings?/:next2?',
  plans: '/:health?/:morehealth?/:lifegoals?/:morelifegoals?/:risk?',
  protection: '/:work/:health/:people/:future/:family/:residence/:more',
  final: '/:work/:health/:people/:future/:family/:residence/:more',
});

export const Pages = () => (
  <Switch>
    <Route path="/intro/:step?" component={Intro} />
    <Route path={`/status${urlParams.status}`} component={Status} />
    <Route path={`/crystal${urlParams.status}`} component={Crystal} />
    <Route path={`/plans${urlParams.status}${urlParams.plans}`} component={Plans} />
    <Route path={`/loading${urlParams.status}${urlParams.plans}`} component={Loading} />
    <Route path={`/protection${urlParams.protection}`} component={Protection} />
    <Route path={`/final${urlParams.final}`} component={Final} />
    <Route component={Landing} />
  </Switch>
);

import { Button } from 'theme-ui';
import React from 'react';
import { useHistory } from 'react-router-dom';
import __ from '../../../hooks/useI18n';

export const BackButton = ({ children }) => {
  const { go } = useHistory();

  // TODO: we shall rather use location.pathname.replace(/\/[^\]+$/)
  return (
    <Button onClick={() => go(-1)} variant="smallCta">
      {children}
      {!children && (
        <>
          <svg width="14" height="7" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.99 5L16 5L16 3L3.99 3L3.99 1.04995e-06L-3.49691e-07 4L3.99 8L3.99 5Z" fill="#3A7D44" />
          </svg>
          {__('Zurück')}
        </>
      )}
    </Button>
  );
};

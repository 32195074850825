import { Flex, Grid } from 'theme-ui';
import { Link } from '../components/core/router/Link';
import { CTAButton, Title, SubTitle } from '../components/core/themed';
import __ from '../hooks/useI18n';

export const Landing = () => (
  <Link to="/intro">
    <Flex variant="flex.colLeft" sx={{ p: 9, height: '70vh' }}>
      <Title sx={{
        fontSize: '7.5rem', fontWeight: 500, color: 'primary', pb: 4,
      }}
      >{__('Hallo!')}</Title>
      <SubTitle sx={{
        variant: 'styles.h1', color: 'primary', fontWeight: 600, lineHeight: '3rem',
      }}
      >{__('Rufe Deinen persönlichen Schutzengel, der Dich auf Deiner Journey begleiten wird.')}</SubTitle>
    </Flex>
    <Grid variant="grids.cta">
      <CTAButton>
        {__('Rufe Alexis')}
      </CTAButton>
    </Grid>
  </Link>
);

/* eslint-disable no-unused-vars */
import gsap from 'gsap';
import Draggable from 'gsap/Draggable';
import InertiaPlugin from 'gsap/InertiaPlugin';
import React, { useLayoutEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Image, Text } from 'theme-ui';
import { useCurrentStepName, usePrevStepName } from '../../hooks/useCurrentStepName';
import { selectionDelimiters, usePropertiesLink } from '../../hooks/usePropertiesLink';
import { steps as stepsConfig } from '../../config';
import { Link } from '../core/router/Link';
import { NextButton } from '../core/router/NextButton';
import { hiddenScrollBars } from '../../config/theme-ui';
import { NavigationBottom } from '../core/NavigationBottom';
import __ from '../../hooks/useI18n';
// import { Button } from '../core/themed';

const styles = {
  options: (length) => ({
    overflow: 'visible',
    variant: length > 4 ? 'flex.left': 'flex.center',
    ...(length > 4 ? {
      '& > div': {
        display: 'flex !important',
      },
    } : {
    }),
    pl: length > 4 ? '8.5vh' : 0,
    overflowX: length > 4 ? 'scroll' : 'visible',
    ...hiddenScrollBars,
    alignItems: 'flex-end',
    border: 'debug',
    width: 'auto',
    pt: 9, // needed, since overflowX: 'scroll' ruins vertical visibility
    'svg, img': {
      maxHeight: '8.5vh',
    },
  }),
  item: (hidden) => ({
    flex: hidden ? '0 1 0vh' : '0 0 11vh',
    mr: '3vh',
    opacity: hidden ? '0 !important' : 1,
    position: hidden ? 'absolute' : 'relative',
    zIndex: hidden ? 0 : 1,

    'button, a': {
      '.icon:after': {
        zIndex: 19,
        content: '""',
        bg: 'tertiary',
        opacity: 1,
        borderRadius: 'fullSize',
        width: '8.5vh',
        height: '8.5vh',
        // transform: 'translateY(-2vh)',
        position: 'absolute',
      },
    },
  }),
  icon: {
    variant: 'flex.left',
    alignItems: 'flex-end',
    width: '8.5vh',
    height: '8.5vh',
    'svg, img': {
      position: 'relative',
      zIndex: 20,
    },
    '.income &': {
      'svg, img': {
        transform: 'translate(1.5vh, -1.2vh)',
        transformOrigin: 'left bottom',
      },
    },
    '.income &#income3': {
      'svg, img': {
        transform: 'translate(1vh , -1.2vh)',
      },
    },
    '.income &#income4': {
      'svg, img': {
        transform: 'translate(0.5vh , -1.2vh)',
      },
    },
    '.belongings &, .morebelongings &': {
      'svg, img': {
        transform: 'translate(0.5vh, -1.2vh)',
        transformOrigin: 'left bottom',
      },
      '&#Drohne': {
        'svg, img': {
          transform: 'translateY(-4vh)',
        },
      },
      '&#Nothing': {
        'svg, img': {
          transformOrigin: 'center !important',
          transform: 'translate(2vh, -2vh)',
        },
      },
    },
    '.family &:nth-of-type(1), .family &:nth-of-type(2)': {
      'svg, img': {
        transform: 'translateX(1.5vh)',
      },
    },
  },
  hoverItem: {
    'button, a': {
      '>:hover': {
        transform: 'none !important',
        '.icon:after': {
          opacity: 0.8,
        },
      },
    },
    'svg, img': {
      transition: 'transform 0.2s',
      transformOrigin: '30% bottom',
    },
    '&:hover': {
      'svg, img': {
        transform: 'scale(1.5) !important',
        // filter: 'drop-shadow( 3px -3px 2px rgba(50, 50, 50, .7))',
      },
    },
  },
  itemText: {
    bg: 'grey.2',
    fontSize: 'm',
    // variant: 'styles.small',
    minWidth: '16vh',
    height: '4.5vh',
    color: 'primary',
    mt: '2vh',
    mb: 0,
    borderRadius: 3,
  },
};

// TODO: might refactor to DraggableContext
let draggable = null;

export const OptionItemTextStyles = ({ children, sx }) => <Text sx={{ ...styles.itemText, ...sx }} className="itemText">{children}</Text>;

const OptionClickContext = React.createContext();

export function OptionClickProvider({ children }) {
  const [last, setLast] = React.useState({});

  // TODO: might refactor to DraggableContext
  const setLastAndDisableDraggable = (p, callback) => {
    if (callback)callback();
    setLast(p);
  };

  return (
    <OptionClickContext.Provider value={[last, setLastAndDisableDraggable]}>
      {children}
    </OptionClickContext.Provider>
  );
}

export const useOptionClickContext = () => React.useContext(OptionClickContext);

export const OptionItem = ({
  id, icon: Icon, children, link, sx, getLink, multiple,
  // text: TextComponent,
}) => {
  const newLink = getLink ? getLink() : usePropertiesLink(link, id);
  const ref = useRef(null);
  const [, setLast] = useOptionClickContext();

  return (
    <Box sx={{ ...styles.item(), ...sx }} className="item">
      <Link to={id !== undefined && newLink} ref={ref} onClick={() => setLast(ref.current.getBoundingClientRect(), !multiple && draggable.disable)}>
        <Box variant="buttons.primary" className="itemIconText">
          {Icon && (
            <Box sx={styles.icon} className="icon" id={`${Icon}`.replace(/.*\/([\w-]+)\..*$/gi, '$1')}>
              {typeof Icon === 'object' && <Icon />}
              {typeof Icon === 'string' && <Image src={Icon} alt={children} />}
            </Box>
          // ) : (
          //   <OptionItemTextStyles sx={{
          //     minWidth: 'unset',
          //     px: 5,
          //     py: 4,
          //     borderBottomLeftRadius: 0,
          //     bg: 'primary',
          //   }}
          //   >
          //     <TextComponent />
          //   </OptionItemTextStyles>
          )}
          {children && (
            <OptionItemTextStyles>
              {children}
            </OptionItemTextStyles>
          )}
        </Box>
      </Link>
    </Box>
  );
};

const OptionItemHoverStyles = ({ hidden, children, sx }) => (
  <Box className={`hover-item ${hidden ? 'selected' : ''}`} sx={{ ...styles.item(hidden), ...styles.hoverItem, ...sx }}>
    {children}
  </Box>
);

const getItemWidth = () => gsap.getProperty('#options .hover-item:first-of-type', 'width');
const getContainerWidth = () => gsap.getProperty('main > section:first-of-type', 'width');

export const Options = ({
  sx, items, link, delay, config, match, steps=stepsConfig.status, additionalRevealAnimation,
}) => {
  const [itemWidth, setItemWidth] = React.useState(getItemWidth());
  const currentStepName = useCurrentStepName(match, steps);
  const prevStepName = usePrevStepName(match, steps);
  const newMultipleLink = usePropertiesLink(config && config.completeMultipleLink);
  const location = useLocation();

  gsap.registerPlugin(Draggable, InertiaPlugin);

  const containerRef = useRef(null);

  const isItemHidden = (index) => config && config.multiple && config.completeMultipleLink && prevStepName && match.params[prevStepName] && match.params[prevStepName].split(selectionDelimiters.multiple).includes(`${index}`);

  window.addEventListener('resize', () => {
    // TODO: proper cleanup for the timeline?
    setItemWidth(getItemWidth());
  });

  useLayoutEffect(() => {
    setItemWidth(getItemWidth());

    // TODO: might refactor to DraggableContext
    draggable = new Draggable(containerRef.current, {
      inertia: true,
      type: 'scroll',
    }).disable();
    if (additionalRevealAnimation) draggable.enable();

    gsap.timeline({
      delay,
    })
      .fromTo('#options .hover-item', {
        opacity: 0,
        x: 200,
        scale: 0.5,
      }, {
        opacity: 1,
        x: 0,
        scale: 1,
        stagger: 0.1,
        ease: 'back',
      })
      .fromTo('#options', {
        ...(additionalRevealAnimation ? {
          x: 0,
          width: getContainerWidth(),
        }: { x: 0, width: '100%' }),
      }, {
        ...(additionalRevealAnimation ? {
          x: -1 * itemWidth * (items.length - 3.5),
          width: itemWidth * (items.length * 2),
        }: { x: 0, width: '100%' }),
        duration: 1.2,
        repeat: 1,
        yoyo: 1,
      }, 0);
  }, [containerRef, items, itemWidth]);

  console.log(location.pathname); // DIRTY HACK: need a hook to get re-rendered on location chnage (when using getLink())

  return (
    <Box sx={{ ...styles.container, ...sx }}>
      <Box id="options" className={currentStepName} sx={{ ...styles.options(items.length) }} ref={containerRef}>
        {items && items.map(({
          icon, text: TextComponent, getLink, sx: itemSx,
        }, index) => (
          <OptionItemHoverStyles key={`${index}`} hidden={isItemHidden(index)} sx={itemSx}>
            <OptionItem
              key={`${index}`}
              icon={icon}
              id={index}
              link={link}
              getLink={getLink}
              multiple={config && config.multiple}
            >
              {TextComponent && __(TextComponent())}
            </OptionItem>
          </OptionItemHoverStyles>
        ))}
      </Box>

      <NavigationBottom className="navigation">
        {config && config.multiple && (
          <NextButton link={newMultipleLink} disabled={!config.completeMultipleLink} />
        )}
      </NavigationBottom>

    </Box>
  );
};

import { Box } from 'theme-ui';
import { Link, useLocation } from 'react-router-dom';
import { statusSteps } from '../../config';
import __ from '../../hooks/useI18n';

const styles = {
  item: (match) => ({
    variant: 'styles.small',
    transition: 'all 0.2s ease-out',
    bg: 'primary',
    color: 'primary',
    minWidth: '2vh',
    width: '2vh',
    overflow: 'hidden',
    height: '3px',
    borderRadius: '13px',
    flex: '0 1 auto',
    px: 3,
    mx: 1,
    opacity: !match ? 0.3: 1,
    ...(match && !match[match.length-1]? {
      mx: 3,
      py: 3,
      px: 4,
      flex: '0 1 auto',
      color: 'background',
      width: 'max-content',
      height: 'auto',
    }:{}),
  }),
};

export const ProgressNav = () => {
  const location = useLocation();

  if (location.pathname.match(/^(\/intro.*|\/protection.*|\/final.*|\/|\/de|\/en|\/fr|)$/)) return null;

  return (
    <Box
      as="nav"
      sx={{
        variant: 'flex.center',
        maxWidth: 'tabletMaxWidth',
        height: 'headerHeight',
        width: 'fullSize',
        position: 'fixed',
        zIndex: 'nav',
        top: 0,
      }}
    >
      {statusSteps.map(({ title, regex }, index) => (
        <Link to={location.pathname.replace(/\/[^/]+$/, '')} key={index}>
          <Box sx={styles.item(location.pathname.match(regex))}>{__(title(location.pathname))}</Box>
        </Link>
      ))}
    </Box>

  );
};

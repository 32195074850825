import { Box } from 'theme-ui';
import { useLocation, useHistory } from 'react-router-dom';
import IOSDatePicker from 'react-mobile-datepicker';
import React, { useState } from 'react';
import gsap from 'gsap';
import { NextButton } from './router/NextButton';
import { useTranslate } from '../../hooks/useI18n';

const monthMap = [
  'Januar',
  'Februar',
  'März',
  'April',
  'Mai',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'Dezember',
];

const styles = {
  navigation: {
    variant: 'flex.bottomButtons',
    justifyContent: 'flex-end',
  },
  container: {
    width: '48vh',
    margin: 'auto',

    '.datepicker.default': {
      position: 'relative',
      width: '100%',
      bg: 'transparent',

      '.datepicker-caption': {
        p: 0,
      },
      '.datepicker-caption-item': {
        color: 'primary',
        fontSize: 'm',
        fontWeight: 'medium',
      },
      '.datepicker-content': {
        gap: 4,
        // p: 0,
      },
      '.datepicker-viewport': {
        height: '100px',
        // overflowX: 'visible',
      },
      '.datepicker-scroll': {
        // transform: 'translateY(-100px) !important',
      },
      '.datepicker-scroll>li': {
        // variant: 'styles.small',
        fontSize: 'm',
        fontWeight: 'medium',
        lineHeight: 'auto',
        m: 0,
        color: 'tertiary',
        // height: '20px',
        // lineHeight: '20px',
      },
      '.datepicker-scroll>li.disabled': {
        color: 'gray.3',
      },
      // the selected
      '.datepicker-scroll>li:nth-of-type(6)': {
        // height: '40px',
        // lineHeight: '40px',
        color: 'primary',
        fontSize: 'm',
        fontWeight: 'medium',
      },
      '.datepicker-wheel': {
        // bg: 'gray.2',
        // border: 'none',
        borderColor: 'primary',
        borderWidth: '2px',
        borderRadius: '0 !important',
      },
      '.datepicker-col-1': {
        m: 0,
      },
      'div.datepicker-col-1:nth-of-type(2)': {
        minWidth: '12vh',
        '.datepicker-wheel': {
          borderTopLeftRadius: 3,
          borderBottomLeftRadius: 3,
        },
        '.datepicker-scroll>li': {
          // textAlign: 'left',
          // pl: '10px',

        },
      },
      '.datepicker-col-1:last-child .datepicker-wheel': {
        borderTopRightRadius: 3,
        borderBottomRightRadius: 3,
      },
      '.datepicker-navbar': {
        '.datepicker-navbar-btn:last-child': {
          float: 'left',
        },
      },
    },
  },
};

export const DatePicker = () => {
  const defaultDate = new Date(1999, new Date().getMonth(), new Date().getDate());
  const translate = useTranslate();

  const dateConfig = {
    date: {
      format: translate('dateformat:DD'),
      caption: translate('Tag'),
      step: 1,
    },
    month: {
      format: value => translate(monthMap)[value.getMonth()],
      caption: translate('Monat'),
      step: 1,
    },
    year: {
      format: translate('dateformat:YYYY'),
      caption: translate('Jahre'),
      step: 1,
    },
  };

  const [date, setDate] = useState(defaultDate);

  const { go } = useHistory();
  const { pathname } = useLocation();

  const containerRef = React.useRef(null);

  // scalarX
  const calc3dStyles = () => ({
    // x: (index) => -scalarX * (5-index)*(5-index),
    // y: (index) => (index<5 ? 1 : -1) * 3 * (5-index)*(5-index),
    // scaleY: (index) => 1 - (((5-index)*(5-index))/30),
    // transform: (index) => `skew(${(index<5 ? 1 : -1)*scalarX*0.5*((5-index)*(5-index))}deg)`,
  });

  React.useLayoutEffect(() => {
    gsap.set('.datepicker-col-1:first-child .datepicker-scroll>li', calc3dStyles(-4));
    gsap.set('.datepicker-col-1:nth-of-type(2) .datepicker-scroll>li', calc3dStyles(1));
    gsap.set('.datepicker-col-1:nth-of-type(3) .datepicker-scroll>li', calc3dStyles(4));
  }, []);

  return (
    <>
      <Box id="datepicker" sx={styles.container} ref={containerRef}>
        <IOSDatePicker
          dateConfig={dateConfig}
          value={date || defaultDate}
          min={new Date(1950, 0, 1)}
          max={new Date(2007, 0, 1)}
          theme="default"
          isOpen
          isPopup={false}
          showCaption
          showHeader={false}
          showFooter={false}
          // confirmText="Fertig"
          // cancelText="Stornieren"
          // onSelect={date => goTo(`${pathname}/${new Date(date).toISOString().substring(0, 10)}`)}
          onChange={setDate}
          onCancel={() => go(-1)}
        />

      </Box>

      <Box sx={styles.navigation}>
        <NextButton link={`${pathname}/${new Date(date).toISOString().substring(0, 10)}`} disabled={!date} />
      </Box>
    </>
  );
};

import { Box } from 'theme-ui';
import React from 'react';
import { useSignIn } from 'react-auth-kit';
import __ from '../../hooks/useI18n';
import { CTAButton, Paragraph, Input } from '../core/themed';
import { useSignInUser } from '../auth/users';

const styles = {
  overlay: {
    bg: 'white',
    width: 'fullSize',
    height: 'browserHeight',
    variant: 'flex.center',
  },
  form: {
    border: 'double',
    borderColor: 'main',
    variant: 'flex.colCenter',
    flex: '0 0 auto',
    width: 'auto',
    p: 8,
    input: {
      flex: '0 0 auto',
      width: 'auto',
    },
  },
};
const Form = ({ children }) => <Box sx={styles.overlay}><Box sx={styles.form} as="form">{children}</Box></Box>;

export const VersionInfo = ({ env = process.env }) => (<Paragraph>{env.REACT_APP_NAME} v{env?.REACT_APP_VERSION}</Paragraph>);

export const Login = ({ children }) => {
  const [password, setPassword] = React.useState('');
  const storeAuth = useSignIn();
  const signIn = useSignInUser();
  const onSubmit = async (e) => {
    e.preventDefault();
    const { token, user } = await signIn({ id: password });

    if (token) {
      storeAuth({
        token,
        authState: user,
        expiresIn: process.env.REACT_APP_AUTH_EXPIRATION_MINUTE || 1200,
      });
    }
  };

  return (
    <Form>
      <h1>{__('Protected demo')}</h1>
      <h2>{__('Please sign in for content')}</h2>
      <Input onChange={e => setPassword(e.target.value)} value={`${password}`} type="password" />
      {children}
      <CTAButton onClick={onSubmit} type="submit">{__('Sign in')}</CTAButton>
    </Form>
  );
};

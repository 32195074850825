import { useState } from 'react';
import { Input } from './themed';

export const CurrencyInput = ({
  value: initialValue, currency = '€', ...props
}) => {
  const [value, setValue] = useState(initialValue);
  const setCurrencyValue = v => {
    const newValue = parseFloat(`${v}`.replace(currency, ''));
    setValue(Number.isNaN(newValue) ? 0 : newValue);
  };
  return (<Input {...props} onChange={e => setCurrencyValue(e.target.value)} value={`${value}${currency}`} />);
};

import React from 'react';
import {
  Grid, Box, Flex, Button, useThemeUI,
} from 'theme-ui';
import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import { Bubbles } from '../components/layout/Bubbles';
import { steps } from '../config';

import { CTAButton, Paragraph, Title } from '../components/core/themed';
import { NavigationBottom } from '../components/core/NavigationBottom';
import { Link } from '../components/core/router/Link';
import __ from '../hooks/useI18n';

import { ReactComponent as IconShieldCheckmark } from '../static/images/final/shield.checkmark.svg';
import { ReactComponent as IconRight } from '../static/images/final/arrow.right.svg';
import { ReactComponent as IconDown } from '../static/images/final/arrow.down.svg';
import { ReactComponent as IconUp } from '../static/images/final/arrow.up.svg';
import cloudButton from '../components/layout/cloud-button.png';

const styles = {
  buttons: {
    position: 'fixed',
    bottom: 0,
    variant: 'flex.colCenter',
    width: 'fullSize',
    height: 'auto',
    pt: '3vh',
    // backgroundImage: 'linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%)',
    backgroundImage: `url(${cloudButton})`,
    backgroundSize: '100% 21vh',
    backgroundPosition: 'bottom center',
    backgroundRepeat: 'no-repeat',
    // '& > *': {
    //   zIndex: 'float',
    // },
    // '&:before': {
    //   content: '""',
    //   variant: 'grids.buttonWithCloud',
    // },
    'button, a': {
      width: 'auto !important',
    },
  },
  title: {
    pt: '29vh',
    fontWeight: 600,
  },
  options: (showAll) => ({
    variant: 'flex.colCenter',
    justifyContent: 'flex-start',
    px: 'pageMargin',
    '&:after': {
      content: '""',
      pb: '23vh', // needed because of the overlay
      order: 8,
    },

    '& .item:nth-of-type(5)': {
      order: 6,
    },
    '& .item:nth-of-type(6)': {
      order: 7,
    },
    '& .item:nth-of-type(7)': {
      order: 5,
    },
    ...(showAll? {/* no override on showing all */}: {
      '& .item:nth-of-type(5)': {
        height: '0',
        flex: '0 1 0',
        borderWidth: 0,
      },
      '& .item:nth-of-type(6)': {
        height: '0',
        flex: '0 1 0',
        borderWidth: 0,
      },
    }),
  }),
  item: (active) => ({
    transition: 'all 0.3s ease',
    flex: '0 1 6.7vh',
    height: '6.7vh',
    overflow: 'hidden',
    placeItems: 'flex-start',
    alignItems: 'center',
    gridTemplateColumns: '6vh 1fr 1.5vh',
    borderBottom: 'thin',
    borderBottomColor: 'gray.3',
    a: {
      width: 'auto',
      textAlign: 'left',
    },
    '& #checkmark': {
      opacity: !!active * 1,
      mr: 8,
    },
    '.shield': {
      width: 'auto',
      height: '3.85vh',
    },
    svg: {
      height: '2.5vh',
      width: '100%',
    },
  }),
  price: {
    fontSize: '3.1rem',
    pb: 4,
  },
  moreButton: {
    textAlign: 'center',
    p: 3,
  },
  openCollapseIcon: {
    svg: {
      height: '1.5vh',
      width: '100%',
    },
  },
};

const OptionItem = ({
  active, children, link,
}) => (
  <Grid sx={styles.item(active)} className="item">
    <Link to={link}><IconShieldCheckmark className="shield" /></Link>
    <Link to={link}>{children}</Link>
    <Link to={link}><IconRight /></Link>
  </Grid>
);

export const Final = ({ match }) => {
  const [showAll, setShowall] = React.useState(false);
  const optionsRef = React.useRef(null);
  const { theme } = useThemeUI();

  gsap.registerPlugin(ScrollToPlugin);

  React.useEffect(() => {
    gsap.to('main section', showAll ? {
      duration: 1, scrollTo: parseInt(theme.sizes.getRelativeHeight(0.23), 10),
    }: {
      duration: 0.2, scrollTo: 1,
    });
  }, [showAll]);

  return (
    <Flex variant="flex.colCenter">
      <Bubbles texts={steps.final.bubbles} />

      <Title sx={styles.title}>{__('Dein persönlicher Schutzplan')}</Title>

      <Box sx={styles.options(showAll)} id="options" ref={optionsRef}>
        {steps.final.options && steps.final.options.map(({
          id, icon, text: TextComponent, link,
        }, index) => (
        // <OptionItemHoverStyles key={`${index}`} hidden={isItemHidden(index)}>
          <OptionItem
            key={`${index}`}
            icon={icon}
            id={id}
            link={link}
            active={!!+match.params[id]}
          >
            {TextComponent && <Paragraph sx={{ p: 0 }}>{__(TextComponent())}</Paragraph>}
          </OptionItem>
        // </OptionItemHoverStyles>
        ))}
        <Button onClick={() => setShowall(!showAll)} sx={styles.openCollapseButton}>
          <Paragraph sx={styles.moreButton}>
            {__(showAll ? 'Weniger Versicherungen ansehen' : 'Weitere Versicherungen ansehen')}
            <br />
            <Box sx={styles.openCollapseIcon}>{showAll ? <IconUp /> : <IconDown />}</Box>
          </Paragraph>
        </Button>
      </Box>

      <Box sx={styles.buttons}>
        <Title sx={styles.price}>
          {+match.params.work ? '85' : '35'} {__('€ p. Monat')}
        </Title>

        <CTAButton sx={{ bg: 'green1' }}>
          {__('Vertrag abschließen')}
        </CTAButton>

        <NavigationBottom />
      </Box>

    </Flex>
  );
};

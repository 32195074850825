import iconAuszubildende from '../static/images/status/options/profession/Auszubildende_r.svg';
import iconBeamter from '../static/images/status/options/profession/Beamter_in.svg';
import iconHaushalter from '../static/images/status/options/profession/Haushalter_in.svg';
import iconRuheständler from '../static/images/status/options/profession/Ruhestandler_in.svg';
import iconStudent from '../static/images/status/options/profession/Student_in.svg';
import iconSelbständige from '../static/images/status/options/profession/Selbstandige_r.svg';
import iconFreiberufler from '../static/images/status/options/profession/Freiberufler_in.svg';
import iconAngestellte from '../static/images/status/options/profession/Angestellte_r.svg';

import iconIncome1 from '../static/images/status/options/income/income1.svg';
import iconIncome2 from '../static/images/status/options/income/income2.svg';
import iconIncome3 from '../static/images/status/options/income/income3.svg';
import iconIncome4 from '../static/images/status/options/income/income4.svg';

import iconSingle from '../static/images/status/options/partnership/single.svg';
import iconSingleKid from '../static/images/status/options/partnership/single.kid.svg';
import iconCouple from '../static/images/status/options/partnership/couple.svg';
import iconFamily from '../static/images/status/options/partnership/family.svg';

import iconHausEigentum from '../static/images/status/options/residence/Haus Eigentum.svg';
import iconHausMiete from '../static/images/status/options/residence/Haus Miete.svg';
import iconHausVerwandschaft from '../static/images/status/options/residence/Haus Verwandschaft.svg';

import iconArtwork from '../static/images/status/options/property/Artwork.svg';
import iconPlant from '../static/images/status/options/property/Plant.svg';

import iconBicycle from '../static/images/status/options/belongings/Bicycle.svg';
import iconCar from '../static/images/status/options/belongings/Car.svg';
import iconCat from '../static/images/status/options/belongings/Cat.svg';
import iconDrohne from '../static/images/status/options/belongings/Drohne.svg';
import iconHund from '../static/images/status/options/belongings/Hund.svg';
import iconMotor from '../static/images/status/options/belongings/Motor.svg';
import iconPferd from '../static/images/status/options/belongings/Pferd.svg';
import iconNothing from '../static/images/status/options/belongings/Nothing.svg';

import iconClinic from '../static/images/status/options/health/clinic.svg';
import iconChiefDoctor from '../static/images/status/options/health/chief-doctor.svg';
import iconSingleRoom from '../static/images/status/options/health/single-room.svg';
import iconDental from '../static/images/status/options/health/dental.svg';
import iconSecondDoctor from '../static/images/status/options/health/second-doctor.svg';
import iconNoExtra from '../static/images/status/options/health/no-extra.svg';

import iconGoalFamily from '../static/images/status/options/lifegoals/family.svg';
import iconGoalHouse from '../static/images/status/options/lifegoals/house.svg';
import iconGoalBoat from '../static/images/status/options/lifegoals/boat.svg';
import iconGoalHolidayHome from '../static/images/status/options/lifegoals/holiday-home.svg';
import iconGoalCompany from '../static/images/status/options/lifegoals/company.svg';
import iconGoalTravel from '../static/images/status/options/lifegoals/travel.svg';

import { ReactComponent as IconArbeitskraft } from '../static/images/protection/options/Arbeitskraft.svg';
import { ReactComponent as IconDeineLiebsten } from '../static/images/protection/options/Deine Liebsten.svg';
import { ReactComponent as IconGesundheit } from '../static/images/protection/options/Gesundheit.svg';
import { ReactComponent as IconSachenUndPersonen } from '../static/images/protection/options/Sachen und Personen.svg';
import { ReactComponent as IconWeitereAnsehen } from '../static/images/protection/options/Weitere ansehen.svg';
import { ReactComponent as IconWohnung } from '../static/images/protection/options/Wohnung.svg';
import { ReactComponent as IconZukunft } from '../static/images/protection/options/Zukunft.svg';

import { DatePicker } from '../components/core/DatePicker';
import { DateValue } from '../components/core/DateValue';

import __ from '../hooks/useI18n';

import {
  getPropertiesLinkMultipleItem,
  getPropertiesLinkNextSection, getPropertiesLinkNextStep,
} from '../hooks/usePropertiesLink';
import { RiskSelector } from '../components/RiskSelector';
import { SubTitle } from '../components/core/themed';

const futurePlantext = (pathname) => {
  const root = `${pathname}`.match((/^\/([^/]+)/));

  const subStep = (() => {
    switch (root && root[1]) {
    case 'crystal':
      return 1;
    default:
      // eslint-disable-next-line no-case-declarations
      const correct = Object.freeze({
        2: 2,
        3: 2,
        4: 3,
        5: 3,
        6: 4,
      });
      return correct[(pathname.match(/\//g) || []).length-8];
    }
  })();

  return (`Zukunftspläne ${subStep}/4`);
};

/* eslint-disable-line quote-props */
export const statusSteps = [
  { title: () => ('Geburtsdatum'), regex: /^\/(status|crystal|plans|loading)(.*)$/ },
  { title: () => ('Beschäftigung'), regex: /^\/(status|crystal|plans|loading)\/[\w-,]+(.*)$/ },
  { title: () => ('Nettoeinkommen'), regex: /^\/(status|crystal|plans|loading)(\/[\w-,]+){2}(.*)$/ },
  { title: () => ('Familienstand'), regex: /^\/(status|crystal|plans|loading)(\/[\w-,]+){3}(.*)$/ },
  { title: () => ('Wohnverhältnis'), regex: /^\/(status|crystal|plans|loading)(\/[\w-,]+){4}(.*)$/ },
  { title: () => ('Einrichtung'), regex: /^\/(status|crystal|plans|loading)(\/[\w-,]+){5}(.*)$/ },
  { title: () => ('Schätze'), regex: /^\/(status|crystal|plans|loading)(\/[\w-,]+){6,7}(.*)$/ },
  { title: futurePlantext, regex: /^\/(status|crystal|plans|loading)(\/[\w-,]+){8,13}(.*)$/ },
  { title: () => ('Ergebnis'), regex: /^\/loading(\/[\w-,]+){14}(.*)$/ },
];
/* eslint-enable quote-props */

const belongings = {
  bubbles: (['Das ist sehr vernünftig von Dir, man kann sich es auch mit einfacheren Mitteln gemütlich machen.', 'Welche Dinge in Deinem Leben sind Dir wichtig. Was sind Deine persönlichen Schätze?']),
  config: {
    multiple: true,
  },
  options: [
    { icon: iconCat, text: () => ('Katze') },
    { icon: iconHund, text: () => ('Hund') },
    { icon: iconBicycle, text: () => ('Fahrrad') },
    { icon: iconMotor, text: () => ('Motorrad') },
    { icon: iconCar, text: () => ('Auto') },
    { icon: iconPferd, text: () => ('Pferd') },
    { icon: iconDrohne, text: () => ('Drohne') },
    {
      icon: iconNothing,
      text: () => ('Nichts davon'),
      getLink: () => (window.location.pathname.match(/^\/(status|crystal|plans|loading)(\/[\w-,]+){7}(.*)$/) ? window.location.pathname.replace(/\/[^/]*$/, '/-1'): `${window.location.pathname}/-1`),
    },
  ],
};

const health = {
  bubbles: (['Was ist Dir besonders wichtig bei der Absicherung Deiner Gesundheit?']),
  config: {
    multiple: true,
  },
  options: [
    { icon: iconClinic, text: () => ('Freie Klinik und Krankenhauswahl') },
    { icon: iconChiefDoctor, text: () => ('Chefarztbehandlung') },
    { icon: iconSingleRoom, text: () => ('Einzelzimmer') },
    { icon: iconDental, text: () => ('Bestmögliche Zahnabsicherung') },
    { icon: iconSecondDoctor, text: () => ('Medizinische Zweitmeinung') },
    {
      icon: iconNoExtra,
      text: () => ('Ich brauche keine Extrawürste'),
      getLink: () => window.location.pathname.replace(/((\/[\w-,]+){10}).*$/, '$1/-1'),
    },
  ],
};
const lifegoals = {
  bubbles: (['Was sind Lebenswünsche, die Du Dir erfüllen möchtest?']),
  config: {
    multiple: true,
  },
  options: [
    { icon: iconGoalFamily, text: () => ('Meine eigene Familie gründen') },
    { icon: iconGoalHouse, text: () => ('In meine eigenen vier Wänden ziehen') },
    { icon: iconGoalBoat, text: () => ('Mir meinen Traum vom Boot erfüllen') },
    { icon: iconGoalHolidayHome, text: () => ('Ferienwohnung erwerben') },
    { icon: iconGoalCompany, text: () => ('Meine eigene Firma gründen') },
    { icon: iconGoalTravel, text: () => ('Mir eine Weltreise ermöglichen') },
  ],
};

export const steps = {
  status: {
    birthdate: {
      bubbles: (['Toll, lass uns loslegen! Wann bist Du geboren?']),
      optionComponent: DatePicker,
      component: DateValue,
    },
    profession: {
      bubbles: (['Cool, dann bist Du Steinbock. Super, dass Du noch so jung bist, dann kann ich Dich länger beschützen.', 'Welche der folgenden Kategorien beschreibt Dein Beschäftigungsstatus aktuell am besten?']),
      options: [
        { icon: iconSelbständige, text: () => ('Selbständige*r') },
        { icon: iconFreiberufler, text: () => ('Freiberufler*n') },
        { icon: iconBeamter, text: () => ('Beamter*n') },
        { icon: iconAuszubildende, text: () => ('Auszubildende*r') },
        { icon: iconAngestellte, text: () => ('Angestellte*r') },
        { icon: iconStudent, text: () => ('Student*n') },
        { icon: iconRuheständler, text: () => ('Ruheständler*n') },
        { icon: iconHaushalter, text: () => ('Haushalter*n') },
      ],
    },
    income: {
      bubbles: (['Danke Dir, dann bist Du unter den zwei Drittel der Erwerbstätigen, die angestellt sind. Ein pünktliches, sicheres Gehalt ist auch etwas Schönes.', 'Teilst Du mir grob Dein monatliches Nettoeinkommen mit, damit ich das Schutzprofil passgenau auf Deine persönliche Situation anpassen kann?']),
      options: [
        { icon: iconIncome1, text: () => ('Unter 2.000€') },
        { icon: iconIncome2, text: () => ('2.000€ bis 3.000€') },
        { icon: iconIncome3, text: () => ('3.000€ bis 4.000€') },
        { icon: iconIncome4, text: () => ('Über 4.000€') },
      ],
    },
    family: {
      bubbles: (['Danke Dir! Ich habe mal kurz für Dich nachgerechnet: Bis zu Deiner Rente wirst Du über 1,5 Millionen Euro verdienen und da sind zukünftige Gehaltserhöhungen noch nicht mit eingerechnet.', 'Verrätst Du mir nun noch, wie es mit Deinen familiären Verhältnissen aussieht?']),
      options: [
        { icon: iconSingle, text: () => ('Single ohne Kinder') },
        { icon: iconSingleKid, text: () => ('Single mit Kindern') },
        { icon: iconCouple, text: () => ('Partnerschaft ohne Kinder') },
        { icon: iconFamily, text: () => ('Partnerschaft mit Kindern') },
      ],
    },
    residence: {
      bubbles: (['Glückwunsch! Dann hast Du Deine bessere Hälfte bereits gefunden. Gemeinsam lässt sich das Leben doch umso mehr genießen.', 'Wir brauchen alle ein Dach über dem Kopf – wie sieht Deines aus?']),
      options: [
        { icon: iconHausMiete, text: () => ('Miete') },
        { icon: iconHausEigentum, text: () => ('Eigentum') },
        { icon: iconHausVerwandschaft, text: () => ('Mietfrei') },
      ],
    },
    property: {
      bubbles: (['Mit 23 Jahren ist das Wohnen zur Miete auch sinnvoll. Du kannst später immer noch entscheiden, ob Du Dir Deine eigenen vier Wände zulegen möchtest.', 'Besitzt Du Einrichtungsgegenstände, die in Summe, mehr als 20.000 Euro wert sind?']),
      options: [
        { icon: iconArtwork, text: () => ('Ja') },
        { icon: iconPlant, text: () => ('Nein') },
      ],
    },
    belongings,
    // this step shall exactly match with the previous, we just need a different link
    morebelongings: {
      ...belongings,
      config: {
        ...belongings.config,
        completeMultipleLink: ({ pathname }) => getPropertiesLinkNextSection({ pathname, more: '-1', root: '/crystal' }),
      },
      link: ({ pathname, more }) => getPropertiesLinkMultipleItem({ pathname, more }),
    },
  },
  crystal: {
    bubbles: (['Super, wir haben es auch schon fast geschafft. Lass uns nun zum Abschluss noch zu Deinen persönlichen Zukunftsplänen kommen.']),
    link: ({ pathname }) => getPropertiesLinkNextSection({ pathname, more: '-1', root: '/plans' }),
  },
  plans: {
    health,
    morehealth: {
      ...health,
      config: {
        ...health.config,
        completeMultipleLink: ({ pathname }) => getPropertiesLinkNextStep({ pathname, more: '-1' }),
      },
      link: ({ pathname, more }) => getPropertiesLinkMultipleItem({ pathname, more }),
    },
    lifegoals,
    morelifegoals: {
      ...lifegoals,
      config: {
        ...lifegoals.config,
        completeMultipleLink: ({ pathname }) => getPropertiesLinkNextStep({ pathname, more: '-1' }),
      },
      link: ({ pathname, more }) => getPropertiesLinkMultipleItem({ pathname, more }),
    },
    risk: {
      bubbles: (['Klasse, danke Dir! Nun noch eine letzte Frage, dann sind wir durch. Welcher Risikotyp bist Du?']),
      optionComponent: RiskSelector,
      link: ({ pathname }) => getPropertiesLinkNextSection({ pathname, more: '-1', root: '/loading' }),
    },
  },
  loading: {
    bubbles: (['Danke Dir vielmals für Deine Antworten und Deine Offenheit. Ich werde nun Deinen Schutzplan individualisieren.']),
  },
  protection: {
    bubbles: (['Danke für Deine Geduld! Auf Basis Deiner Antworten haben ich den für Dich passenden Schutzplan bestehend aus vier Paketen ermittelt.', 'Mit Tap auf das jeweilige Schild erfährst Du mehr darüber, warum diese Absicherung für Dich relevant ist und wie Du diese individualisieren kannst.']),
    options: [
      {
        icon: null,
        text: () => <SubTitle sx={{ variant: 'styles.h3' }}>{__('Empfohlene Absicherungen')}</SubTitle>,
        sx: { flex: '1 0 100%' },
      },
      { icon: IconArbeitskraft, text: () => ('Arbeitskraft//'), link: '/detail' },
      { icon: IconGesundheit, text: () => ('Gesundheit') },
      { icon: IconSachenUndPersonen, text: () => ('Sachen//und Personen') },
      { icon: IconZukunft, text: () => ('Zukunft') },
      {
        icon: null,
        text: () => <SubTitle sx={{ variant: 'styles.h3' }}>{__('Weitere Absicherungen')}</SubTitle>,
        sx: { flex: '1 0 100%' },
      },
      { icon: IconDeineLiebsten, text: () => ('Deine//Liebsten') },
      { icon: IconWohnung, text: () => ('Wohnung') },
      { icon: IconWeitereAnsehen, text: () => ('Weitere//ansehen') },
    ],
  },
  final: {
    bubbles: (['Mein Vorschlag ist auf Deine persönliche Situation abgestimmt. Mit einem Tab auf den jeweiligen Schutzplan erfährst Du mehr darüber.', 'Hier erkläre ich Dir meine Empfehlung und zeige Dir mehr Details zu dem Schutzplan.']),
    options: [
      { id: 'work', text: () => 'Arbeitskraft', link: ({ pathname }) => getPropertiesLinkNextStep({ pathname, more: 1, maxDepth: 9 }) },
      { id: 'health', text: () => 'Gesundheit' },
      { id: 'people', text: () => 'Sach- und Personenschäden' },
      { id: 'future', text: () => 'Zukunftsvorsorge' },
      { id: 'family', text: () => 'Deine Liebsten' },
      { id: 'residence', text: () => 'Wohnung' },
    ],
  },
};

import React from 'react';
import localization from '../config/i18n';
import { getQueryParam, getRootPath } from './useQueryParam';

export const supportedLanguages = Object.freeze({ de: 'de', en: 'en', fr: 'fr' });
export const defaultLang = Object.keys(supportedLanguages)[0];

export const getBaseLang = () => {
  if (supportedLanguages[getRootPath()]) return supportedLanguages[getRootPath()];
  if (supportedLanguages[getQueryParam('lang')]) return supportedLanguages[getQueryParam('lang')];
  return process.env.REACT_APP_LANGUAGE || defaultLang;
};

export const I18NContext = React.createContext();

export function I18NProvider({ children }) {
  const [language, setLanguage] = React.useState(getBaseLang());

  return (
    <I18NContext.Provider value={[language, setLanguage]}>
      {children}
    </I18NContext.Provider>
  );
}

export const useLanguage = () => {
  const [language] = React.useContext(I18NContext);
  return language;
};

export const useRotateLanguage = () => {
  const [language, setLanguage] = React.useContext(I18NContext);
  const current = Object.keys(supportedLanguages).findIndex(l => l === language || '');
  const next = (current>=0 && current+1 < Object.keys(supportedLanguages).length) ? current+1 : 0;
  return () => setLanguage(Object.keys(supportedLanguages)[next]);
};

const i18n = (title, language = getBaseLang() || defaultLang, myLocalization = localization) => {
  const key = `${title}`.trim();
  return (myLocalization[key] && myLocalization[key][language] && myLocalization[key][language]) || title;
};

export const translate = (title, language = getBaseLang(), myLocalization = localization) => {
  if (typeof title === 'object' && title.constructor.name === 'Array') return title.map(t => i18n(t, language || defaultLang, myLocalization));
  return i18n(title, language || defaultLang, myLocalization);
};

export const useTranslate = () => {
  const language = useLanguage();
  return (title) => translate(title, language);
};

export default (title) => {
  const language = useLanguage();
  return translate(title, language);
};

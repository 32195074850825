import { Box, Flex } from 'theme-ui';
import { Bubbles } from '../components/layout/Bubbles';
import { steps, chatDelay } from '../config';

// import { usePropertiesLink } from '../hooks/usePropertiesLink';
import { useCurrentStepName } from '../hooks/useCurrentStepName';
import { Options } from '../components/layout/Options';

import checkmark from '../static/images/checkmark.png';

const styles = {
  options: {
    pt: '14vh',
    height: (theme) => theme.sizes.getRelativeHeight(0.86),
    zIndex: 'options',
    '#options': {
      height: '100%',
      px: '4vh',
      pb: '4vh',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      '.hover-item': {
        'svg, img': {
          transition: 'transform 0.2s',
          transformOrigin: 'center bottom',
        },
        '.item': {
          flex: '0 0 auto',
          'a:after': {
            variant: 'flex.center',
            content: '"+"',
            fontSize: 'xxl',
            fontWeight: '900',
            color: 'primary',
            bg: 'gray.4',
            width: '4.1vh',
            height: '4.1vh',
            lineHeight: '3vh',
            top: '13.5vh',
            right: '1vh',
            border: 'solid 0.6vh #DAE8FB',
            borderRadius: 'fullSize',
            position: 'absolute',
            zIndex: 'popup',
          },
        },
        '&.selected, &.selected .item': {
          opacity: '1 !important',
          position: 'relative',
          zIndex: 1,
        },
        '&.selected .item': {
          'a:after': {
            content: '""',
            border: 'solid 0.6vh #DAE8FB',
            bg: '#DAE8FB',
            backgroundImage: `url(${checkmark})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'center center',
          },
        },
        '&:hover': {
          'svg, img': {
            transform: 'scale(1.2) !important',
            // filter: 'drop-shadow( 3px -3px 2px rgba(50, 50, 50, .7))',
          },
        },
      },
      '.item': {
        'button, a': {
          '.icon': {
            width: 'auto',
            height: '17vh',
            'svg, img': {
              maxHeight: '17vh',
            },
            '&:after': {
              content: '""',
              display: 'none',
            },
          },
        },
      },
    },
  },
};

export const Plans = ({ match }) => {
  const currentStepName = useCurrentStepName(match, steps.plans);
  const currentStep = steps.plans[currentStepName];

  if (!currentStep) return null;

  return (
    <Flex variant="flex.colCenter">
      <Bubbles texts={currentStep.bubbles} sx={styles.bubbles} />

      {currentStep.options && !currentStep.optionComponent && <Options
        {...currentStep}
        items={currentStep.options}
        sx={styles.options}
        delay={currentStep.bubbles.length*chatDelay}
        match={match}
        steps={steps.plans}
      />}

      {currentStep.optionComponent && <Box sx={styles.options}><currentStep.optionComponent {...currentStep} steps={steps.plans} /></Box>}

    </Flex>
  );
};
